import {
  toBigWithDefault,
  type MarketAccountDetail,
  type SubAccountReconCheckpoint,
  type SubAccountReconCheckpointEvalStatusEnum,
  type SubAccountReconCheckpointStatusEnum,
  type TreeRow,
} from '@talos/kyoko';
import type {
  ICellRendererFunc,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { compact } from 'lodash';
import type { ReconOverviewBlotterColumnIDs } from './useSubAccountReconOverviewBlotterColumns';

export type SubAccountReconOverviewRow = ReconSubAccountRow | ReconAssetRow | ReconMarketAccountRow;

/**
 * Given a SubAccountReconCheckpoint, constructs the rows necessary for visualising this data type in a tree blotter.
 * The produced structure is:
 * - SubAccountRow
 *   - AssetRow
 *     - MarketAccountRow 1
 *     - MarketAccountRow 2
 *     - ...
 */
export function subAccReconCheckpointToBlotterRows(
  checkpoint: SubAccountReconCheckpoint
): SubAccountReconOverviewRow[] {
  const subAccountLevelRow = new ReconSubAccountRow(checkpoint);
  const assetLevelRow = new ReconAssetRow(checkpoint);
  const marketAccountLevelRows = checkpoint.MarketAccountDetails?.MarketAccounts.map(
    mktAccDetail => new ReconMarketAccountRow(checkpoint, mktAccDetail)
  );

  return [subAccountLevelRow, assetLevelRow, ...compact(marketAccountLevelRows)];
}

export class ReconSubAccountRow implements TreeRow {
  AccountsKey: string;
  /** ID of the checkpoint from which this instance originates */
  checkpointID: string;

  get rowID() {
    return this.AccountsKey;
  }

  get dataPath() {
    return [this.AccountsKey];
  }

  get hasBreak() {
    return false;
  }

  SubAccounts: string[];

  groupColumnValueGetter(params: ValueGetterParams<ReconSubAccountRow>): string[] {
    return this.SubAccounts;
  }

  groupColumnFormattedValueGetter({ value, context }: ValueFormatterParams<ReconSubAccountRow, string[]>): string {
    return value.map(sa => context.current.subAccountsByName?.get(sa)?.DisplayName ?? sa).join(', ');
  }

  groupColumnFilterValueGetter(params: ValueGetterParams) {
    return this.groupColumnValueGetter(params);
  }

  constructor(checkpoint: SubAccountReconCheckpoint) {
    this.AccountsKey = checkpoint.AccountsKey;
    this.checkpointID = checkpoint.ID;
    this.SubAccounts = checkpoint.SubAccountDetails.SubAccounts.map(s => s.SubAccount);
  }
}

export class ReconAssetRow implements TreeRow {
  AccountsKey: string;
  /** ID of the checkpoint from which this instance originates */
  checkpointID: string;
  SubAccounts: string[];
  Asset: string;
  Status: SubAccountReconCheckpointStatusEnum;
  EvalStatus: SubAccountReconCheckpointEvalStatusEnum;
  SubAccountAmount?: string;
  MarketAccountAmount?: string;

  StartTime: string;
  EndTime: string;
  LastUpdateTime: string;

  // dont wanna expose the undefinedness so make these private and expose through getters which default to 0-ish values
  private Breaks?: number;
  private BreakAmount?: string;

  /** Gets a count of breaks */
  get breaks(): number {
    return this.Breaks != null ? this.Breaks : 0;
  }

  /** The total Amount on the breaks within this checkpoint */
  get breakAmount(): string {
    return toBigWithDefault(this.BreakAmount, 0).toFixed();
  }

  /** True if the BreakAmount is non-zero */
  get hasBreak() {
    return !toBigWithDefault(this.BreakAmount, 0).eq(0);
  }

  get dataPath() {
    return [this.AccountsKey, this.Asset];
  }

  get rowID() {
    return this.dataPath.join('-');
  }

  groupColumnValueGetter(params: ValueGetterParams<ReconAssetRow>) {
    return this.Asset;
  }

  groupColumnFormattedValueGetter({ value }: ValueFormatterParams<ReconAssetRow, string>): string {
    return value;
  }

  groupColumnFilterValueGetter(params: ValueGetterParams<ReconAssetRow>) {
    return this.groupColumnValueGetter(params);
  }

  groupColumnInnerCellRenderer(params: ICellRendererParams<ReconAssetRow, string>) {
    // Try to resolve the Asset column and its cell renderer. If found, use it
    const assetCellRenderer: ICellRendererFunc | undefined = params.columnApi
      .getColumn('Asset' satisfies ReconOverviewBlotterColumnIDs)
      ?.getColDef().cellRenderer;

    if (assetCellRenderer) {
      return assetCellRenderer(params);
    }

    // Else just return the base string value in this case
    return params.value;
  }

  constructor(checkpoint: SubAccountReconCheckpoint) {
    this.AccountsKey = checkpoint.AccountsKey;
    this.checkpointID = checkpoint.ID;
    this.Asset = checkpoint.Asset;
    this.SubAccountAmount = checkpoint.SubAccountDetails.Amount;
    this.MarketAccountAmount = checkpoint.MarketAccountDetails?.Amount;
    this.Breaks = checkpoint.Unmatched?.Count;
    this.BreakAmount = checkpoint.Unmatched?.Amount;
    this.Status = checkpoint.Status;
    this.EvalStatus = checkpoint.EvalStatus;
    this.SubAccounts = checkpoint.SubAccountDetails.SubAccounts.map(s => s.SubAccount);
    this.StartTime = checkpoint.StartTime;
    this.EndTime = checkpoint.EndTime;
    this.LastUpdateTime = checkpoint.LastUpdateTime;
  }
}

export class ReconMarketAccountRow implements TreeRow {
  AccountsKey: string;
  /** ID of the checkpoint from which this instance originates */
  checkpointID: string;
  Asset: string;
  MarketAccount: string;
  MarketAccountAmount: string;
  Status: SubAccountReconCheckpointStatusEnum;
  Breaks?: number;
  BreakAmount?: string;

  get dataPath() {
    return [this.AccountsKey, this.Asset, this.MarketAccount];
  }

  get rowID() {
    return this.dataPath.join('-');
  }

  get hasBreak() {
    return false;
  }

  groupColumnValueGetter(params: ValueGetterParams<ReconMarketAccountRow>) {
    return this.MarketAccount;
  }

  groupColumnFormattedValueGetter(params: ValueFormatterParams<ReconMarketAccountRow, string>) {
    return params.context.current.marketAccountsByName?.get(this.MarketAccount)?.DisplayName ?? this.MarketAccount;
  }

  groupColumnFilterValueGetter(params: ValueGetterParams) {
    return this.groupColumnValueGetter(params);
  }

  constructor(checkpoint: SubAccountReconCheckpoint, mktAccDetail: MarketAccountDetail) {
    this.AccountsKey = checkpoint.AccountsKey;
    this.checkpointID = checkpoint.ID;
    this.Asset = checkpoint.Asset;
    this.MarketAccount = mktAccDetail.MarketAccount;
    this.MarketAccountAmount = mktAccDetail.Amount;
    this.Breaks = mktAccDetail.Unmatched.Count;
    this.BreakAmount = mktAccDetail.Unmatched.Amount;
    this.Status = mktAccDetail.Status;
  }
}
