import * as Sentry from '@sentry/browser';
import type {
  BaseExportParams,
  CellDoubleClickedEvent,
  ColumnMovedEvent,
  ColumnResizedEvent,
  ColumnRowGroupChangedEvent,
  ColumnVisibleEvent,
  ExpandCollapseAllEvent,
  FirstDataRenderedEvent,
  RowClassRules,
  RowClickedEvent,
  RowDoubleClickedEvent,
  RowGroupOpenedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import type { FlashCellsParams } from 'ag-grid-community/dist/lib/gridApi';
import type {
  ColDef,
  ColumnApi,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GridApi,
  GridOptions,
  RowNode,
} from 'ag-grid-enterprise';
import { compact, isEmpty, isEqual, uniqBy } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { useMixpanel } from '../../contexts/MixpanelContext';
import { useDynamicCallback } from '../../hooks';
import { useConstant } from '../../hooks/useConstant';
import { MixpanelEvent, MixpanelEventProperty } from '../../tokens/mixpanel';
import { WarningSeverity } from '../../types/WarningSeverity';
import { EMPTY_ARRAY } from '../../utils';
import { WARNING_ROW_CLASSNAME } from '../AgGrid/types';
import { useBlotterTableContext } from './BlotterTableContext';
import type { BlotterTablePauseProps } from './BlotterTablePauseButton.types';
import { getCustomColDefProperties } from './columns';
import { getAgGridColId } from './columns/getAgGridColId';
import type { Column } from './columns/types';
import { useGetDefaultContextMenuItems } from './contextMenu';
import { useBlotterQuickFilter } from './filters/useBlotterQuickFilter';
import { compileTransactions } from './helpers';
import {
  AGGRID_AUTOCOLUMN_ID,
  BlotterDensity,
  type BlotterTableRow,
  type BlotterTableSort,
  type ExportDataAsCsvParams,
  type ExportDataAsExcelParams,
  type GetSheetDataForExcelParams,
  type TalosBlotterExportParams,
  type UseBlotterTable,
  type UseBlotterTableProps,
  type UseBlotterTableUtilitiesOutput,
} from './types';
import { useColumnDefs } from './useColumnDefs';
import {
  alphabeticalGroupOrder,
  cellCsvSafety,
  getParamsFormatted,
  removeUnnecessarySeparators,
  selectOrUnselectAllNodesInGroup,
} from './utils';

export function useBlotterTable<R>({
  dataObservable,
  pinnedRowDataPipe,
  pinnedRowDataObs,
  rowID,
  columns: flatColumns,
  groupableColumns,
  domLayout,
  rowHeight,
  flashRows: initialFlashRows,
  animateRows,
  fitColumns = false,
  density,
  rowSelection,
  showPinnedRows = true,
  clientLocalFilter,
  renderEmpty,
  onRowSelectionChanged,
  onColumnsChanged,
  onSortChanged,
  onFilterChanged,
  onBlotterFilterChanged,
  onDoubleClickRow,
  onDoubleClickCell,
  onClickRow,
  sort,
  filter,
  getContextMenuItems,
  getExtraMainMenuItems,
  handleClickJson,
  suppressAggFuncInHeader = true,
  onFirstDataRendered,
  context: customBlotterContext,
  rowGroupPanelShow,
  quickSearchParams,
  autoGroupColumnDef,
  pauseParams,
  supportColumnColDefGroups,
  customColumnUpdate,
  ...otherGridOptions
}: UseBlotterTableProps<R>): UseBlotterTable<R> {
  const columnsToUse = groupableColumns ?? flatColumns;
  const {
    rowHeightBlotterTableCompact,
    rowHeightBlotterTableDefault,
    rowHeightBlotterTableComfortable,
    rowHeightBlotterTableVeryComfortable,
  } = useTheme();
  const [paused, setPaused] = useState(false);
  const [api, setApi] = useState<GridApi>();
  const [columnApi, setColumnApi] = useState<ColumnApi>();
  const flashRows = useConstant(initialFlashRows);
  const onGridReady: NonNullable<GridOptions<R>['onGridReady']> = useCallback(params => {
    setApi(params.api);
    setColumnApi(params.columnApi);
  }, []);
  useEffect(() => {
    setApi(undefined);
    setColumnApi(undefined);
  }, []);

  const rowNodeId = useRef(rowID);
  useEffect(() => {
    rowNodeId.current = rowID;
  }, [rowID]);
  const getRowId = useCallback(({ data }) => (rowNodeId.current ? data?.[rowNodeId.current] : null), []);

  // Subscribe to data and add to blotter
  const dataState = useConstant(new Set<string>());
  useEffect(() => {
    if (api && dataObservable && !paused) {
      const subscription = dataObservable.subscribe(next => {
        if ((isEmpty(next.data) && dataState.size === 0) || rowID == null) {
          api.applyTransactionAsync({ add: [] });
          return;
        }
        const transactions = compileTransactions(dataState, next.data, rowID as string, !!next.initial);

        if (!isEmpty(transactions.add) || !isEmpty(transactions.update) || !isEmpty(transactions.remove)) {
          api.applyTransactionAsync(transactions, () => {
            if (next.initial || !flashRows) {
              return;
            }
            for (const flash of flashRows) {
              api.flashCells({
                rowNodes: transactions[flash]
                  .map((row: any) => api.getRowNode(row[rowNodeId.current as string]))
                  .filter(row => row != null) as RowNode[],
                flashDelay: 5000,
                fadeDelay: 2000,
              });
            }
          });
        }
      });

      return () => {
        // Clear out blotter and unsubscribe
        subscription.unsubscribe();
      };
    }
  }, [api, dataObservable, dataState, flashRows, rowID, paused]);

  // We allow the implementer to either pass a pipe in order to chain of for example an internal observable,
  // Or we allow the implementer to provide their own pinnedRowDataObs. But only one of these can be used
  const pinnedRowDataObsToUse = useMemo(
    () => (dataObservable && pinnedRowDataPipe ? dataObservable.pipe(pinnedRowDataPipe) : pinnedRowDataObs),
    [dataObservable, pinnedRowDataPipe, pinnedRowDataObs]
  );

  // Sub to pinnedRowDataObservable and update pinned top row data when it fires
  useEffect(() => {
    if (api && pinnedRowDataObsToUse && !paused) {
      let timer: ReturnType<typeof setTimeout> | null = null;
      const subscription = pinnedRowDataObsToUse.subscribe(next => {
        // Always clear any previous timer before doing anything new
        if (timer != null) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          if (showPinnedRows) {
            // When updating the pinned top row data (for now we only support one pinned top row),
            // we grab the current row and update its internal data to have the row not "re-mount" on each update.
            // Otherwise, on each update, any open context menu stemming from the pinned top row will close
            const pinnedTopRow = api.getPinnedTopRow(0);
            if (pinnedTopRow) {
              pinnedTopRow.setData(next);
            } else {
              api.setPinnedTopRowData([next]);
            }
          }
        }, 0);
      }); // recommended by aggrid to do setTimeout here

      if (!showPinnedRows) {
        api.setPinnedTopRowData([]);
      }

      return () => {
        timer != null && clearTimeout(timer);
        subscription.unsubscribe();
      };
    }
  }, [api, showPinnedRows, pinnedRowDataObsToUse, paused]);

  // Utility functions
  const utilities = useBlotterTableUtilities<R>(api, columnApi);
  const { addRow, getRows, getSelectedRows } = utilities;
  // Create AgGrid ColumnDefs from Columns
  const columnDefs = useColumnDefs<R>(columnsToUse, {
    handleClickJson,
    exportDataAsCSV: utilities.exportDataAsCSV,
    supportColumnColDefGroups,
  });

  // Mechanism for getting current state of columns in our internal definition
  const getColumns = useCallback(() => {
    if (columnApi === undefined) {
      // Throwing in this case as returning an empty array might cause us to overwrite our column definitions by accident
      throw new Error('getColumns() called too early before grid was mounted and columnApi was defined');
    }
    const next: Column[] = [];
    const state = columnApi.getColumnState();
    // AGGRID_AUTOCOLUMN_ID is a special column that we don't handle from getColumnState
    // TODO: Implement ability to restore state for AutoColumn (optionally)
    const columnsFromState = state.filter(columnState => columnState.colId !== AGGRID_AUTOCOLUMN_ID);
    for (const columnState of columnsFromState) {
      const column = flatColumns.find(column => columnState.colId === getAgGridColId(column));
      if (column == null) {
        console.warn(`Could not find column for ${columnState.colId}`, columnState);
      } else {
        next.push({
          ...column,
          width: columnState.width,
          hide: columnState.hide === null ? undefined : columnState.hide,
          // AgGrid throws errors if we try to set rowGroup or rowGroupIndex when treeData is true
          rowGroup: otherGridOptions.treeData ? undefined : columnState.rowGroup ?? undefined,
          rowGroupIndex: otherGridOptions.treeData ? undefined : columnState.rowGroupIndex ?? undefined,
        });
      }
    }
    return next;
  }, [columnApi, flatColumns, otherGridOptions.treeData]);

  // Initial setup
  useBlotterTableInitialSetup<R>({
    autoGroupColumnDef,
    columnDefs,
    sort,
    fitColumns,
    api,
    columnApi,
    hasCustomColumnUpdate: customColumnUpdate != null,
  });

  // Perform custom column updates if needed based on the current state of the blotter and data
  useEffect(() => {
    const cleanup = customColumnUpdate?.({
      dataObservable,
      autoGroupColumnDef,
      columnDefs,
      sort,
      fitColumns,
      api,
      columnApi,
    });
    return cleanup;
  }, [api, autoGroupColumnDef, columnApi, columnDefs, customColumnUpdate, dataObservable, fitColumns, sort]);

  // Event handlers
  useBlotterTableEventHandlers(
    {
      columns: flatColumns,
      sort,
      onColumnsChanged,
      onSortChanged,
      onDoubleClickRow,
      onDoubleClickCell,
      onFirstDataRendered,
      onClickRow,
      getColumns,
    },
    api
  );

  const onSelectionChanged = useCallback(() => {
    const selectedRows = getSelectedRows();
    onRowSelectionChanged != null && onRowSelectionChanged(selectedRows);
  }, [onRowSelectionChanged, getSelectedRows]);

  // Clipboard
  const processCellForClipboard = getParamsFormatted;

  // Context
  const blotterTableContext = useBlotterTableContext();
  const agGridContext = useRef<any>(null);
  useEffect(() => {
    agGridContext.current = {
      ...blotterTableContext,
      ...customBlotterContext,
      getRows,
      addRow,
    };
    api?.refreshCells({ force: true });
  }, [api, blotterTableContext, customBlotterContext, getRows, addRow]);

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  // Context menu
  // This function intercepts the getting of context menu items (called on context menu open) and performs some uniform logic
  const smartGetContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams) => {
      const rightClickedNode = params.node;
      if (rightClickedNode == null) {
        // Shouldn't happen
        Sentry.captureMessage(
          'No right clicked node found when attempting to build the BlotterTable AgGrid Context Menu'
        );
        return [];
      }
      const selectedNodes = params.api.getSelectedNodes().filter(node => node.displayed);
      const isRightClickedNodeInSelection = selectedNodes.some(node => node.id === rightClickedNode.id);

      if (!isRightClickedNodeInSelection) {
        params.api.deselectAll();
      }

      if (rightClickedNode.group) {
        selectOrUnselectAllNodesInGroup(rightClickedNode, true);
      } else if (!rightClickedNode.rowPinned) {
        // We can select the row as long as its not pinned, aggrid doesnt like that
        rightClickedNode.setSelected(true, false);
      }

      // Either call the specified function or fallback to our default
      const items = getContextMenuItems?.(params) ?? getDefaultContextMenuItems(params);
      return removeUnnecessarySeparators(items);
    },
    [getContextMenuItems, getDefaultContextMenuItems]
  );

  // Main menu
  const getMainMenuItems = useMemo(() => {
    return (params: GetMainMenuItemsParams) => {
      const extras = getExtraMainMenuItems ? getExtraMainMenuItems(params) : [];
      if (extras.length > 0) {
        extras.push('separator');
      }
      return removeUnnecessarySeparators(extras.concat(['autoSizeThis', 'autoSizeAll', 'separator', 'resetColumns']));
    };
  }, [getExtraMainMenuItems]);

  const rowClassRules: RowClassRules | undefined = useMemo(() => {
    if (columnsToUse.find(c => c.type === 'warning' && c.hide !== true)) {
      return {
        [WARNING_ROW_CLASSNAME]: params => params?.data?.warningSeverity === WarningSeverity.HIGH,
      };
    }
    return undefined;
  }, [columnsToUse]);

  const doQuickSearchFiltering = quickSearchParams != null;
  const [quickFilterText, setQuickFilterText] = useState('');
  const quickSearchFilter = useBlotterQuickFilter({
    ...quickSearchParams,
    entitySearchKeys: quickSearchParams?.entitySearchKeys ?? EMPTY_ARRAY,
    // We allow the implementer to control the filter text themselves if they want.
    filterStr: quickSearchParams?.filterText ?? quickFilterText,
  });

  const quickSearchFilterCallback = useCallback(
    (node: RowNode<R>) => {
      // If we have provided paramaters to do quick searching, and the quick search filter fails, return false.
      if (doQuickSearchFiltering && !quickSearchFilter(node, api, columnApi)) {
        return false;
      }

      return clientLocalFilter?.(node) ?? true;
    },
    [api, clientLocalFilter, columnApi, doQuickSearchFiltering, quickSearchFilter]
  );

  const gridOptions = useMemo<GridOptions<R> | null>(
    () => ({
      onGridReady,
      getRowId,
      rowHeight:
        rowHeight ??
        (density === BlotterDensity.Compact
          ? rowHeightBlotterTableCompact
          : density === BlotterDensity.Comfortable
          ? rowHeightBlotterTableComfortable
          : density === BlotterDensity.VeryComfortable
          ? rowHeightBlotterTableVeryComfortable
          : rowHeightBlotterTableDefault),
      domLayout,
      animateRows,
      rowSelection,
      rowClassRules,
      onSelectionChanged,
      context: agGridContext,
      getContextMenuItems: smartGetContextMenuItems,
      processCellForClipboard,
      noRowsOverlayComponentParams: { renderEmpty },
      isExternalFilterPresent: () => clientLocalFilter !== undefined || doQuickSearchFiltering,
      doesExternalFilterPass: quickSearchFilterCallback,
      onFilterChanged: onBlotterFilterChanged,
      getMainMenuItems,
      initialGroupOrderComparator: alphabeticalGroupOrder,
      suppressAggFuncInHeader,
      rowGroupPanelShow,
      ...otherGridOptions,
    }),
    [
      onGridReady,
      getRowId,
      rowHeight,
      density,
      rowHeightBlotterTableCompact,
      rowHeightBlotterTableComfortable,
      rowHeightBlotterTableVeryComfortable,
      rowHeightBlotterTableDefault,
      domLayout,
      animateRows,
      rowSelection,
      rowClassRules,
      onSelectionChanged,
      smartGetContextMenuItems,
      processCellForClipboard,
      renderEmpty,
      getMainMenuItems,
      suppressAggFuncInHeader,
      rowGroupPanelShow,
      otherGridOptions,
      clientLocalFilter,
      doQuickSearchFiltering,
      onBlotterFilterChanged,
      quickSearchFilterCallback,
    ]
  );

  useEffect(() => {
    api?.resetRowHeights();
  }, [api, gridOptions?.rowHeight]);

  // Whenever clientLocalFilter or the quickFilterText states change, we tell the blotter that filters have changed
  // onFilterChange called in timeout to allow updates to filter function to get picked up correctly by grid
  useEffect(() => {
    const timeout = setTimeout(() => {
      api?.onFilterChanged();
    });
    return () => {
      clearTimeout(timeout);
    };
  }, [api, clientLocalFilter, quickFilterText, quickSearchParams?.filterText]);

  const pause = useDynamicCallback(() => {
    setPaused(true);
  });

  const resume = useDynamicCallback(() => {
    api?.setRowData([]);
    dataState.clear();
    setPaused(false);
  });

  const pauseProps: BlotterTablePauseProps = {
    pause,
    paused,
    resume,
    showPauseButton: pauseParams?.showPauseButton ?? false,
  };

  return {
    dataObservable,
    gridOptions,
    density,
    filter,
    onFilterChanged,
    sort,
    onSortChanged,
    getColumns,
    blotterTableFiltersProps: {
      quickFilterText,
      onQuickFilterTextChanged: setQuickFilterText,
      ...pauseProps,
    },
    pauseProps,
    ...utilities,
  };
}

export type UseBlotterTableInitialSetupArg<TRowType> = Pick<GridOptions, 'autoGroupColumnDef' | 'api' | 'columnApi'> & {
  columnDefs: NonNullable<GridOptions['columnDefs']>;
  sort?: BlotterTableSort<TRowType>;
  fitColumns: boolean;
  /** If the column update is custom, we don't want to apply the default column update logic */
  hasCustomColumnUpdate: boolean;
};

function useBlotterTableInitialSetup<TRowType = any>({
  autoGroupColumnDef,
  columnDefs,
  sort: initialSorts,
  fitColumns,
  api,
  columnApi,
  hasCustomColumnUpdate,
}: UseBlotterTableInitialSetupArg<TRowType>) {
  // Apply columns.
  // This useEffect has several dependencies, but the only one which should change is column defs. That can change quite often based on settings, runtime configs, etc.
  // So this useEffect should be seen as the useEffect which pushes any changes made to the column setup into the blotter itself both on init and during "runtime" (on-the-fly column def changes)
  useEffect(() => {
    if (api == null || columnApi == null || hasCustomColumnUpdate) {
      return;
    }

    const { workingColumnDefs, workingAutoGroupColumnDef } = applySortsToColumns({
      columnApi,
      columnDefs,
      autoGroupColumnDef,
      initialSorts,
    });

    // Propagate this now-ready set of columns to the blotter.

    // Hack: clear column defs before setting the column defs to our new ones we have prepared.
    // See: https://github.com/ag-grid/ag-grid/issues/2771 and https://stackoverflow.com/questions/53602148/set-new-column-definition-by-setcolumndefs-doesnt-work-anymore
    // setColumnDefs tries to be smart and only applies delta changes on what it thinks has changed.
    // In order to have our new columnDefs to take complete affect, we first clear aggrid's internal column representation such that it accepts our entire
    // new set of columns as its new state
    // In the future, we should move this to a more precise delta model. Currently when we do this below, we repaint the entire grid. In the delta model case,
    // the grid would only update what it needs to.
    //
    // UPDATE (Aug 2024): this hack may not be needed at all (both of these issues seem handled internally by Ag-Grid 24),
    // but we'll keep it for this current release.  At present it causes scroll reset behavior when columns are updated
    api.setColumnDefs([]);
    api.setColumnDefs(workingColumnDefs);
    workingAutoGroupColumnDef && api.setAutoGroupColumnDef(workingAutoGroupColumnDef);

    if (fitColumns) {
      api.sizeColumnsToFit();
    }
  }, [api, columnApi, columnDefs, fitColumns, initialSorts, autoGroupColumnDef, hasCustomColumnUpdate]);
}

function useBlotterTableUtilities<R>(api?: GridApi, columnApi?: ColumnApi): UseBlotterTableUtilitiesOutput<R> {
  const mixpanel = useMixpanel();
  const addRow = useCallback(
    (data?: R) => {
      if (api == null) {
        return;
      }
      api.applyTransactionAsync({ add: [{ ...data }] });
    },
    [api]
  );
  const getRows = useCallback(() => {
    const rows: BlotterTableRow<R>[] = [];
    if (api != null) {
      api.forEachNode(node => {
        rows.push({
          data: node.data,
          setData: newData => node.setData(newData),
          remove: () => api.applyTransactionAsync({ remove: [node.data] }),
          setSelected: (selected: boolean) => {
            node.setSelected(selected);
          },
        });
      });
    }
    return rows;
  }, [api]);
  const getSelectedRows = useCallback(() => {
    if (api != null) {
      const selectedNodes = api.getSelectedNodes();
      const selectedData: BlotterTableRow<R>[] = selectedNodes.map(node => ({
        data: node.data,
        setData: newData => node.setData(newData),
        remove: () => api.applyTransactionAsync({ remove: [node.data] }),
        setSelected: (selected: boolean) => {
          node.setSelected(selected);
        },
      }));
      return selectedData;
    }
    return [];
  }, [api]);

  const getRowsAfterFilter = useCallback(() => {
    if (api == null) {
      return [];
    }

    const rows: BlotterTableRow<R>[] = [];
    api.forEachNodeAfterFilter(node => {
      rows.push({
        data: node.data,
        setData: newData => node.setData(newData),
        remove: () => api.applyTransactionAsync({ remove: [node.data] }),
        setSelected: (selected: boolean) => {
          node.setSelected(selected);
        },
      });
    });

    return rows;
  }, [api]);

  type GetColumnKeysToUseForExportArg = Pick<BaseExportParams, 'columnKeys'> & TalosBlotterExportParams;
  const getColumnKeysToUseForExport = useCallback(
    ({ includeHiddenColumns, ignoredColIds, ignoreColumn, columnKeys }: GetColumnKeysToUseForExportArg) => {
      const startingColumns =
        (includeHiddenColumns ? columnApi?.getColumns() : columnApi?.getAllDisplayedColumns()) ?? [];

      const columnKeysToUse =
        columnKeys ??
        startingColumns.filter(column => {
          const colDef = column.getColDef();

          const customColDef = getCustomColDefProperties(colDef);
          if (customColDef && customColDef.exportable === false) {
            return false;
          }

          if (colDef.headerName === '') {
            return false;
          }

          if (ignoredColIds && ignoredColIds.has(column.getColId())) {
            return false;
          }

          if (ignoreColumn && ignoreColumn(colDef)) {
            return false;
          }

          return true;
        });

      return columnKeysToUse;
    },
    [columnApi]
  );

  const exportDataAsExcel = useCallback(
    (params: ExportDataAsExcelParams) => {
      const columnKeysToUse = getColumnKeysToUseForExport(params);

      return api?.exportDataAsExcel({
        columnKeys: columnKeysToUse,
        processCellCallback: params => {
          return getParamsFormatted(params, 'Excel');
        },
        ...params,
      });
    },
    [api, getColumnKeysToUseForExport]
  );

  const getSheetDataForExcel = useCallback(
    (params: GetSheetDataForExcelParams) => {
      const columnKeysToUse = getColumnKeysToUseForExport(params);

      return api?.getSheetDataForExcel({
        skipRowGroups: true,
        skipPinnedTop: true,
        skipPinnedBottom: true,
        columnKeys: columnKeysToUse,
        processCellCallback: getParamsFormatted,
        ...params,
      });
    },
    [api, getColumnKeysToUseForExport]
  );

  const exportDataAsCSV = useCallback(
    (params: ExportDataAsCsvParams) => {
      const columnKeysToUse = getColumnKeysToUseForExport(params);

      return api?.exportDataAsCsv({
        skipRowGroups: true,
        skipPinnedTop: true,
        skipPinnedBottom: true,
        suppressQuotes: false,
        columnSeparator: ',',
        columnKeys: columnKeysToUse,
        processCellCallback: params => {
          const cellContent = getParamsFormatted(params);
          const safeCsvCellContent = cellCsvSafety(cellContent);
          return safeCsvCellContent;
        },
        ...params,
      });
    },
    [api, getColumnKeysToUseForExport]
  );

  const getDataAsCSV = useCallback(
    (params: ExportDataAsCsvParams) => {
      const columnKeysToUse = getColumnKeysToUseForExport(params);

      return api?.getDataAsCsv({
        skipRowGroups: true,
        skipPinnedTop: true,
        skipPinnedBottom: true,
        suppressQuotes: false,
        columnSeparator: ',',
        columnKeys: columnKeysToUse,
        processCellCallback: getParamsFormatted,
        ...params,
      });
    },
    [api, getColumnKeysToUseForExport]
  );

  /**
   * Expand a group row.
   * The recursively param, defaulting to true, tells the function to open any intermediary group rows as well
   */
  const expandGroupRow = useCallback(
    (nodeKey: string, recursively = true) => {
      if (api == null) {
        return;
      }

      api.forEachNode((node: RowNode) => {
        if (node.group && node.key === nodeKey) {
          node.setExpanded(true);

          // Recursively open all parent nodes up to the root level
          if (recursively) {
            let workingNode = node;
            while (workingNode.level >= 0) {
              if (workingNode.parent == null) {
                break;
              }
              workingNode = workingNode.parent;
              workingNode.setExpanded(true);
            }
          }
        }
      });
    },
    [api]
  );

  const scrollToRow = useCallback(
    (...args: Parameters<GridApi<R>['ensureNodeVisible']>) => {
      if (api == null) {
        return;
      }

      api.ensureNodeVisible(...args);
    },
    [api]
  );
  const scrollVerticallyToColumn = useCallback(
    (...args: Parameters<GridApi<R>['ensureColumnVisible']>) => {
      if (api == null) {
        return;
      }

      api.ensureColumnVisible(...args);
    },
    [api]
  );

  const expandAllGroups = useCallback(() => {
    if (api == null) {
      return;
    }
    mixpanel.track(MixpanelEvent.ExpandAllRows);

    api.expandAll();
  }, [api, mixpanel]);

  const collapseAllGroups = useCallback(() => {
    if (api == null) {
      return;
    }
    mixpanel.track(MixpanelEvent.CollapseAllRows);

    api.collapseAll();
  }, [api, mixpanel]);

  const collapseAllLevelsGreaterThan = useCallback(
    (level: number) => {
      if (api == null) {
        return;
      }
      api.forEachNode(node => {
        if (node.level > level) {
          api.setRowNodeExpanded(node, false);
        }
      });
    },
    [api]
  );

  const setRowGroupColumns = useCallback(
    (...[columnsOrColIds]: Parameters<ColumnApi['setRowGroupColumns']>) => {
      if (columnApi == null) {
        return;
      }

      // Grab the column object if there are any colIds passed in so we have a uniform array going forward
      const columns = columnsOrColIds
        .map(item => {
          if (typeof item === 'string') {
            return columnApi.getColumn(item);
          }

          return item;
        })
        .compact();
      columnApi.setRowGroupColumns(columns);
    },
    [columnApi]
  );

  const addRowGroupColumns = useCallback(
    (colIds: string[]) => {
      if (columnApi == null) {
        return;
      }

      // Grab the existing grouped columns, join with the new ones we're adding, and pass to the set function above
      const currentRowGroupColumns = columnApi.getRowGroupColumns();
      const addedRowGroupColumns = colIds.map(colId => columnApi.getColumn(colId)).compact();
      const uniqueNewRowGroupColumns = uniqBy([...currentRowGroupColumns, ...addedRowGroupColumns], c => c.getColId());
      setRowGroupColumns(uniqueNewRowGroupColumns);
    },
    [columnApi, setRowGroupColumns]
  );

  const removeRowGroupColumns = useCallback(
    (colIds: string[]) => {
      if (columnApi == null) {
        return;
      }

      columnApi.removeRowGroupColumns(colIds);
    },
    [columnApi]
  );

  const getRowGroupColumnIds = useCallback(() => {
    if (columnApi == null) {
      return new Set<string>();
    }

    return new Set(compact(columnApi.getRowGroupColumns().map(c => c.getColId())));
  }, [columnApi]);

  const setColumnsVisible: ColumnApi['setColumnsVisible'] = useCallback(
    (...args) => {
      if (columnApi == null) {
        return;
      }
      columnApi.setColumnsVisible(...args);
    },
    [columnApi]
  );

  const getSort = useCallback(() => {
    if (columnApi == null) {
      return undefined;
    }

    return getBlotterTableSort<R>(columnApi);
  }, [columnApi]);

  const flashCells = useCallback(
    (params: FlashCellsParams) => {
      if (!api) {
        return;
      }

      api.flashCells(params);
    },
    [api]
  );

  const highlightRows = useCallback(
    (rowIDs: string[]) => {
      if (!api) {
        return;
      }

      const nodes = compact(rowIDs.map(id => api.getRowNode(id)));
      if (nodes.length === 0) {
        return;
      }

      // We recursively expand the parent of each node we want to highlight.
      for (const node of nodes) {
        let workingNode = node;
        while (workingNode.level >= 0) {
          if (workingNode.parent == null) {
            break;
          }
          workingNode = workingNode.parent;
          workingNode.setExpanded(true);
        }
      }

      const firstNode = nodes[0];
      setTimeout(() => {
        api.ensureNodeVisible(firstNode, 'middle');
        api.flashCells({ rowNodes: nodes });
      }, 10);
    },
    [api]
  );

  const selectRows = useCallback(
    (rowIDs: string[]) => {
      if (!api) {
        return;
      }

      api.deselectAll();
      rowIDs.forEach(id => api.getRowNode(id)?.setSelected(true));
    },
    [api]
  );

  const refreshClientSideRowModel = useCallback(() => {
    if (!api) {
      return;
    }

    api.refreshClientSideRowModel();
  }, [api]);

  return {
    gridApi: api,
    columnApi,
    addRow,
    getRows,
    getRowsAfterFilter,
    getSelectedRows,
    exportDataAsCSV,
    exportDataAsExcel,
    getDataAsCSV,
    expandGroupRow,
    scrollToRow,
    scrollVerticallyToColumn,
    expandAllGroups,
    collapseAllGroups,
    collapseAllLevelsGreaterThan,
    setRowGroupColumns,
    addRowGroupColumns,
    removeRowGroupColumns,
    getRowGroupColumnIds,
    getSort,
    setColumnsVisible,
    flashCells,
    highlightRows,
    selectRows,
    refreshClientSideRowModel,
    getSheetDataForExcel,
  };
}

function useBlotterTableEventHandlers<R>(
  {
    columns,
    sort,
    onColumnsChanged,
    onSortChanged,
    onFirstDataRendered,
    onDoubleClickRow,
    onDoubleClickCell,
    onClickRow,
    getColumns,
    onRowGroupOpened,
    onExpandOrCollapseAll,
    onRowDataUpdated,
  }: Pick<
    UseBlotterTableProps<R>,
    | 'columns'
    | 'sort'
    | 'onColumnsChanged'
    | 'onSortChanged'
    | 'onFirstDataRendered'
    | 'onDoubleClickRow'
    | 'onDoubleClickCell'
    | 'onClickRow'
    | 'onRowGroupOpened'
    | 'onExpandOrCollapseAll'
    | 'onRowDataUpdated'
  > & { getColumns: () => Column[] },
  api?: GridApi
) {
  const mixpanel = useMixpanel();
  // Column ordering, width, and visibility
  const previousColumns = useRef(columns);
  useEffect(() => {
    if (api == null) {
      return;
    }

    function handleColumnsChanged(
      params: ColumnVisibleEvent | ColumnMovedEvent | ColumnResizedEvent | ColumnRowGroupChangedEvent
    ) {
      if (
        (params.type === 'columnVisible' &&
          params.source !== 'api' /* Fired when we programmatically trigger hide/unhide of columns */ &&
          params.source !== 'toolPanelUi' /* Fired when the user clicks one checkbox in the column picker */ &&
          params.source !==
            'columnMenu') /* Fired when the user clicks the "select all" checkbox in the column picker */ ||
        (params.type === 'columnMoved' && params.source !== 'uiColumnDragged') ||
        (params.type === 'columnResized' &&
          (params.source !== 'uiColumnDragged' || (params as ColumnResizedEvent).finished === false))
      ) {
        // Prevent running columns changed hook for non-user-events or when resize is not finished
        return;
      }

      if (params.type === 'columnRowGroupChanged' && params.source === 'toolPanelUi') {
        // The user has changed the row grouping by dragging and dropping. We want to track this in mixpanel.
        mixpanel.track(MixpanelEvent.DragAndDropGrouping, {
          [MixpanelEventProperty.Columns]: params.columns?.map(column => column.getColId()),
        });
      }

      if (params.column && params.type === 'columnVisible' && params.source === 'toolPanelUi') {
        // The user has changed column visibility in the tool panel. We want to track this in mixpanel.
        mixpanel.track(params.column.isVisible() ? MixpanelEvent.ShowBlotterColumn : MixpanelEvent.HideBlotterColumn, {
          [MixpanelEventProperty.Column]: params.column.getColId(),
        });
      }

      if (onColumnsChanged) {
        const next = getColumns();
        if (!isEqual(previousColumns.current, next)) {
          onColumnsChanged(next, params.api, params.columnApi);
          previousColumns.current = next;
        }
      }
    }

    api.addEventListener('columnVisible', handleColumnsChanged);
    api.addEventListener('columnMoved', handleColumnsChanged);
    api.addEventListener('columnResized', handleColumnsChanged);
    api.addEventListener('columnRowGroupChanged', handleColumnsChanged);
    return () => {
      api.removeEventListener('columnVisible', handleColumnsChanged);
      api.removeEventListener('columnMoved', handleColumnsChanged);
      api.removeEventListener('columnResized', handleColumnsChanged);
      api.removeEventListener('columnRowGroupChanged', handleColumnsChanged);
    };
  }, [api, onColumnsChanged, columns, getColumns, mixpanel]);

  // Sorting
  const previousSort = useRef<BlotterTableSort<R> | undefined>(sort);
  useEffect(() => {
    if (api == null) {
      return;
    }

    function handleSortChanged(params: SortChangedEvent) {
      // Must check for `source` here so that we only run this when the _user click_ is triggering the event.

      if (onSortChanged && params.source === 'uiColumnSorted') {
        const sort = getBlotterTableSort<R>(params.columnApi);
        if (!isEmpty(sort)) {
          if (!isEqual(previousSort.current, sort)) {
            onSortChanged(sort);
            previousSort.current = sort;
          }
        }
      }
    }

    api.addEventListener('sortChanged', handleSortChanged);
    return () => {
      api.removeEventListener('sortChanged', handleSortChanged);
    };
  }, [api, onSortChanged]);

  // Clicking rows
  useEffect(() => {
    if (api == null) {
      return;
    }

    function handleRowDoubleClicked(params: RowDoubleClickedEvent<R>) {
      if (onDoubleClickRow && params.data) {
        onDoubleClickRow(params.data);
      }
    }

    api.addEventListener('rowDoubleClicked', handleRowDoubleClicked);
    return () => {
      api.removeEventListener('rowDoubleClicked', handleRowDoubleClicked);
    };
  }, [api, onDoubleClickRow]);

  // Clicking cells
  useEffect(() => {
    if (api == null) {
      return;
    }

    function handleCellDoubleClicked(params: CellDoubleClickedEvent<R>) {
      if (onDoubleClickCell) {
        onDoubleClickCell(params);
      }
    }

    api.addEventListener('cellDoubleClicked', handleCellDoubleClicked);
    return () => {
      api.removeEventListener('cellDoubleClicked', handleCellDoubleClicked);
    };
  }, [api, onDoubleClickCell]);

  // onFirstDataRendered
  useEffect(() => {
    if (api == null) {
      return;
    }

    function handleFirstDataRendered(params: FirstDataRenderedEvent<R>) {
      if (onFirstDataRendered) {
        onFirstDataRendered(params);
      }
    }

    api.addEventListener('firstDataRendered', handleFirstDataRendered);
    return () => {
      api.removeEventListener('firstDataRendered', handleFirstDataRendered);
    };
  }, [api, onFirstDataRendered]);

  // onRowDataUpdated
  useEffect(() => {
    if (api == null) {
      return;
    }

    function handleRowDataUpdated(params: FirstDataRenderedEvent<R>) {
      if (onRowDataUpdated) {
        onRowDataUpdated(params);
      }
    }

    api.addEventListener('rowDataUpdated', handleRowDataUpdated);
    return () => {
      api.removeEventListener('rowDataUpdated', handleRowDataUpdated);
    };
  }, [api, onRowDataUpdated]);

  // onRowGroupOpened
  useEffect(() => {
    if (api == null) {
      return;
    }
    const eventHandler = (e: RowGroupOpenedEvent) => {
      onRowGroupOpened?.(e);
      mixpanel.track(MixpanelEvent.ExpandRow, { [MixpanelEventProperty.Enabled]: e.expanded });
    };
    api.addEventListener('rowGroupOpened', eventHandler);
    return () => api.removeEventListener('rowGroupOpened', eventHandler);
  }, [api, mixpanel, onRowGroupOpened]);

  // onExpandOrCollapseAll
  useEffect(() => {
    if (api == null) {
      return;
    }
    const eventHandler = (e: ExpandCollapseAllEvent) => {
      onExpandOrCollapseAll?.(e);
      mixpanel.track(e.source === 'expandAll' ? MixpanelEvent.ExpandAllRows : MixpanelEvent.CollapseAllRows);
    };
    api.addEventListener('expandOrCollapseAll', eventHandler);
    return () => api.removeEventListener('expandOrCollapseAll', eventHandler);
  }, [api, mixpanel, onExpandOrCollapseAll]);

  useEffect(() => {
    if (api == null) {
      return;
    }

    function handleRowClicked(params: RowClickedEvent<R>) {
      if (onClickRow && params.data) {
        onClickRow(params.data);
      }

      // If we are clicking on a group node, we want to (un)select all the children within the group as well.
      if (params.node.hasChildren()) {
        // The selection state toggling has already happened at this point, so getting this isSelected gives us the new selected state!
        const newSelectedState = params.node.isSelected();

        // There's a strange case where isSelected returns undefined if the group contents are partially selected, but I can't replicate that....
        // For now just return early. Maybe AgGrid's documentation is incorrect? Can only make it return true or false.
        if (newSelectedState === undefined) {
          return;
        }
        // Apply this new selected state to all children within this group recursively
        selectOrUnselectAllNodesInGroup(params.node, newSelectedState);
      }
    }

    api.addEventListener('rowClicked', handleRowClicked);
    return () => {
      api.removeEventListener('rowClicked', handleRowClicked);
    };
  }, [api, onClickRow]);
}

function getBlotterTableSort<TRowType = any>(columnApi: ColumnApi): BlotterTableSort<TRowType> {
  const sortString = columnApi
    .getColumnState()
    .filter(column => column.sort != null)
    .sort((left, right) => (left.sortIndex ?? 0) - (right.sortIndex ?? 0))
    .map(columnState => `${columnState.sort === 'asc' ? '+' : '-'}${columnState.colId}` as const);

  // casting needed since getColumnState() is not typed, but it should align
  return sortString as BlotterTableSort<TRowType>;
}

/** Apply the sorts of the current column state to the columns and autoGroupColumnDef */
export type ApplySortsToColumnsArg<TRowType> = {
  columnApi: ColumnApi;
  columnDefs: ColDef<TRowType>[];
  autoGroupColumnDef: ColDef<TRowType> | undefined;
  initialSorts: BlotterTableSort<TRowType> | undefined;
};

/** Given input columnDefs and autoGroupColumnDef, apply the existing or persisted state */
export function applySortsToColumns<TRowType>(args: ApplySortsToColumnsArg<TRowType>): {
  workingColumnDefs: ColDef<TRowType>[];
  workingAutoGroupColumnDef: ColDef<TRowType> | undefined;
} {
  const { columnApi, columnDefs, autoGroupColumnDef, initialSorts } = args;
  // We create shallow copies of these objects here so we dont mutate anything passed in
  // The steps below mutate these shallow copies.
  const workingColumnDefs = columnDefs.map(c => ({ ...c }));
  const workingAutoGroupColumnDef = autoGroupColumnDef ? { ...autoGroupColumnDef } : undefined;

  // The returned contents here are the sorts which are currently applied to our blotter.
  const sortedColumnStates = columnApi.getColumnState()?.filter(column => column.sort != null);

  // If there are no sorts applied to the blotter currently, but we're configured to have some amount of default sorting, we apply that default sorting.
  const shouldInitialise = initialSorts != null && sortedColumnStates.length === 0;
  const columns = compact([workingAutoGroupColumnDef, ...workingColumnDefs]);
  if (shouldInitialise) {
    const initialSortArr = Array.isArray(initialSorts) ? initialSorts : [initialSorts];
    initialSortArr.forEach((sort, sortIndex) => {
      const [dir, colId] = [sort.substring(0, 1), sort.substring(1, sort.length)];

      // We allow the user to define an initialSort on the group coldef as well, meaning that we have to include that in this search operation.
      const columnDef = columns.find(columnDef => columnDef.colId === colId);
      if (columnDef != null) {
        columnDef.sort = dir === '+' ? 'asc' : 'desc';
        columnDef.sortIndex = sortIndex;
      }
    });
  } else {
    // Else, we don't need to initialise. Just apply all the sorting the blotter is currently doing to this new set of columns our implementer is passing in.
    sortedColumnStates.forEach(sortedColumnState => {
      const columnDef = columns.find(columnDef => columnDef.field === sortedColumnState.colId);
      if (columnDef != null) {
        columnDef.sort = sortedColumnState.sort;
        columnDef.sortIndex = sortedColumnState.sortIndex;
      }
    });
  }
  return { workingColumnDefs, workingAutoGroupColumnDef };
}
