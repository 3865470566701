import {
  Box,
  ControlPrefix,
  FormControlSizes,
  FuseAutocompleteResult,
  itemIsAutocompleteGroup,
  SearchSelect,
  useMarketAccountsContext,
  type AutocompleteGroup,
  type RenderResultFunc,
  type SearchSelectProps,
} from '@talos/kyoko';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useCallback, useMemo } from 'react';
import {
  getPortfolioViewActions,
  usePortfolioViewStateSelector,
} from '../stateManagement/portfolioViewLayoutSlice.hooks';

const renderResult: RenderResultFunc<Item> = (searchResults, disabled) => {
  return FuseAutocompleteResult(searchResults, disabled);
};

interface Item {
  key: string;
  itemId: string;
  label: string;
}

const ALL_MARKET_ACCOUNT: Item = {
  key: 'ALL_MARKET_ACCOUNT',
  itemId: '',
  label: 'All Market Accounts',
};

const constantCallbacks: Pick<SearchSelectProps<Item>, 'getLabel' | 'getGroup' | 'scrollSelectionIntoViewIndex'> = {
  getLabel: item => {
    if (item.key === ALL_MARKET_ACCOUNT.key) {
      return ALL_MARKET_ACCOUNT.label;
    }
    return item.label;
  },
  getGroup: item => {
    if (item.key === ALL_MARKET_ACCOUNT.key) {
      return 'All Market Accounts';
    }
    return 'Market Accounts';
  },
};

const { changeMarketAccountId } = getPortfolioViewActions();
export const PMSMarketAccountSelector = () => {
  const { selectedMarketAccountId } = usePortfolioViewStateSelector();
  const { marketAccountsList, isMarketAccountActive } = useMarketAccountsContext();
  const dispatch = useAppStateDispatch();

  const marketAccountOptions = useMemo(() => {
    const result = marketAccountsList
      .filter(item => isMarketAccountActive(item))
      .map((item): Item => {
        return {
          key: item.Name,
          itemId: item.Name,
          label: item.DisplayName ?? item.Name,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

    return [ALL_MARKET_ACCOUNT].concat(result);
  }, [isMarketAccountActive, marketAccountsList]);

  const selectedAccount = useMemo(() => {
    return marketAccountOptions.find(option => option.itemId === selectedMarketAccountId);
  }, [marketAccountOptions, selectedMarketAccountId]);

  const onChange = useCallback(
    (selectedItem: Item | undefined) => {
      dispatch(changeMarketAccountId(selectedItem ? selectedItem.itemId : undefined));
    },
    [dispatch]
  );

  const scrollSelectionIntoViewIndex = useCallback(
    (scrollItems: (Item | AutocompleteGroup<Item>)[], item: Item) => {
      const index = scrollItems.findIndex(
        option => !itemIsAutocompleteGroup(option) && option.itemId === selectedAccount?.itemId
      );
      return index;
    },
    [selectedAccount]
  );

  return (
    <Box w="300px" data-testid="market-account-select">
      <SearchSelect<Item>
        prefix={<ControlPrefix>Market Account:</ControlPrefix>}
        placeholder="Select..."
        selection={selectedAccount}
        renderResult={renderResult}
        dropdownWidth="300px"
        maxHeight={310}
        size={FormControlSizes.Small}
        showDescriptionInButton
        fuseDistance={100}
        options={marketAccountOptions}
        onChange={onChange}
        getLabel={constantCallbacks.getLabel}
        getGroup={constantCallbacks.getGroup}
        scrollSelectionIntoViewIndex={scrollSelectionIntoViewIndex}
      />
    </Box>
  );
};
