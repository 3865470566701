import { SubAccountReconMatch } from '@talos/kyoko';
import { compact } from 'lodash';

export const RECON_MATCH_PROVIDER_TALOS = 'Talos';

export function buildReconMatchRows(reconMatch: SubAccountReconMatch): ReconMatchRow[] {
  // From the single recon match backend entity, we build two rows. One for the sub account "ledger event(s)", and one for the
  // market account "ledger event"
  const subAccountRow = new ReconMatchSubAccountRow(reconMatch);
  const marketAccountRow = new ReconMatchMarketAccountRow(reconMatch);

  return [subAccountRow, marketAccountRow];
}

export interface ReconMatchRow extends SubAccountReconMatch {
  marketAccount?: string;
  provider?: string;
  amount?: string;
  avgCost?: string;
  avgCostCurrency?: string;
  comments?: string[];

  rowID: string;
}

export class ReconMatchSubAccountRow extends SubAccountReconMatch implements ReconMatchRow {
  get marketAccount() {
    return this.MarketAccountEvent?.MarketAccount;
  }

  get rowID() {
    return `${this.ID}-sub-account`;
  }

  get provider() {
    return RECON_MATCH_PROVIDER_TALOS; // sub account rows are hardcoded to this
  }

  get amount() {
    return this.SubAccountEvents?.Amount;
  }

  get avgCost() {
    return this.SubAccountEvents?.Events?.at(0)?.AvgCost;
  }

  get avgCostCurrency() {
    return this.SubAccountEvents?.Events?.at(0)?.AvgCostCurrency;
  }

  get comments() {
    return compact(this.SubAccountEvents?.Events?.map(event => event.Comments));
  }
}

export class ReconMatchMarketAccountRow extends SubAccountReconMatch implements ReconMatchRow {
  get rowID() {
    return `${this.ID}-market-account`;
  }

  // For the market account row, we override the break amount to be 0. The break amount solely exists on the sub account row.
  // That is the row we are resolving.
  override get breakAmount() {
    return '0';
  }

  get marketAccount() {
    return this.MarketAccountEvent?.MarketAccount;
  }

  get provider() {
    return this.MarketAccountEvent?.Market;
  }

  get amount() {
    return this.MarketAccountEvent?.Amount;
  }

  get avgCost() {
    return this.MarketAccountEvent?.AvgCost;
  }

  get avgCostCurrency() {
    return this.MarketAccountEvent?.AvgCostCurrency;
  }

  get comments() {
    return compact([this.MarketAccountEvent?.Comments]);
  }
}
