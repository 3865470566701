import {
  ACTION,
  BlotterDensity,
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  DEFAULT_MAX_ROWS,
  ExportDialog,
  FormControlSizes,
  IconName,
  MixpanelEvent,
  MixpanelEventProperty,
  SummaryLine,
  TRADE,
  Toggle,
  WSBlotterTableMaxRecordsReachedWarning,
  columnToColumnState,
  createCSVFileName,
  filterByColumnMainMenuItems,
  formattedDateForSubscription,
  getTradeAmount,
  getTradeQuantity,
  parseDate,
  useAccordionFilterBuilder,
  useBlotterTableExtrasMenu,
  useConstant,
  useDisclosure,
  useDynamicCallback,
  useMixpanel,
  usePersistedBlotterTable,
  useTradeColumns,
  useWsBlotterTable,
  type BlotterTableFilter,
  type BlotterTableRow,
  type Column,
  type ColumnState,
  type Trade,
} from '@talos/kyoko';
import type { GetMainMenuItemsParams } from 'ag-grid-enterprise';
import { OMSView } from 'components/OMS/OMSView';
import { useTradeMenu } from 'containers/Blotters/Trades/TradeMenu';
import {
  colIDToFilterBuilderKey,
  useTradeFilter,
  type BlotterTableTradeFilter,
  type UseTradeFilterOutput,
} from 'containers/Blotters/Trades/useTradeFilter';
import * as routes from 'containers/Routes/routes';
import type { GenerateOrderDetailsRoute } from 'containers/Trading/Markets/OrderDetails/types';
import { useFeatureFlag, useRoleAuth, useUser } from 'hooks';
import { isEmpty, isEqual, keys, pick } from 'lodash';
import { useDisplaySettings } from 'providers/AppConfigProvider';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useContextBlotterFilter } from 'providers/ContextBlotterFilterProvider/useContextBlotterFilter';
import { OrgConfigurationKey, useOrgConfiguration } from 'providers/OrgConfigurationProvider';
import { useSubAccounts } from 'providers/SubAccountsContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { exportOMSTrades } from 'utils/blotterQueries';
import { v1 as uuid } from 'uuid';
import { prime } from '../../../components/OMS/ManualTradeView/ManualTradeSlice';
import { openView } from '../../../components/OMS/OMSSlice';
import { DEFAULT_TRADE_COLUMNS } from './columns';

export const ENTITY_SEARCH_KEYS: (keyof Trade)[] = DEFAULT_TRADE_COLUMNS.map(col => {
  if (typeof col === 'string') {
    return col;
  } else {
    return col.field as keyof Trade;
  }
});

interface FilteredTradesParams {
  /** ID for common blotter type persistance */
  blotterPersistID: string;
  tabLabel?: string;
  orderID?: string;

  defaultColumns: (keyof Trade | Partial<Column>)[];
  defaultFilter: BlotterTableFilter;
  onCloneTab?: (filter: BlotterTableFilter, columns: ColumnState[]) => void;
  initialIsOpen?: boolean;

  showCreateManualTrade?: boolean;
  showAPIOrdersToggle?: boolean;
  generateOrderDetailsRoute?: GenerateOrderDetailsRoute;
  showOpenOrderDetails?: boolean;
}

export const FilteredTrades = function FilteredTrades({
  blotterPersistID,
  tabLabel,
  defaultColumns,
  defaultFilter,
  orderID,
  onCloneTab,
  initialIsOpen,
  showCreateManualTrade,
  showAPIOrdersToggle,
  generateOrderDetailsRoute = routes.getOrderDetailsRoute,
  showOpenOrderDetails,
}: FilteredTradesParams) {
  const mixpanel = useMixpanel();
  const { getConfig } = useOrgConfiguration();
  const user = useUser();

  const { filterPropsMutator, additionalFilterState } = useContextBlotterFilter<UseTradeFilterOutput<Trade>>();
  // only show DateRangePicker if it's not used in the additionalFilterState
  const showDateRangePicker = !(additionalFilterState && 'DateRange' in additionalFilterState);

  const { subAccountsByName } = useSubAccounts();
  const { homeCurrency } = useDisplaySettings();
  const { isAuthorized } = useRoleAuth();
  const dispatch = useAppStateDispatch();

  const [selectedTrades, setSelectedTrades] = useState<Trade[]>([]);

  const columns = useTradeColumns({ defaultColumns: defaultColumns ?? DEFAULT_TRADE_COLUMNS });

  const persistedBlotterTable = usePersistedBlotterTable(blotterPersistID, {
    columns,
    filter: defaultFilter,
    sort: '-TransactTime',
  });

  const filteredTrades = useTradeFilter({
    persistedBlotterTable,
    filterByOrderID: !!orderID,
    additionalFilterState,
  });
  const {
    initialFilter,
    clientSideFilter: clientLocalFilter,
    blotterTableFilterProps,
    changeFilter,
    filterBuilderProps,
    filterableProperties,
  } = useMemo(() => filterPropsMutator(filteredTrades), [filterPropsMutator, filteredTrades]);

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialIsOpen },
    filterBuilderProps,
  });

  const { openClause } = filterBuilderAccordion;

  const handleRowSelectionChanged = useCallback((selectedRows: BlotterTableRow<Trade>[]) => {
    setSelectedTrades(selectedRows.map(row => row.data));
  }, []);

  const blotterTag = useConstant(`FILTERED_TRADES_${uuid()}`);

  const exportDialog = useDisclosure();

  const getExtraMainMenuItems = useCallback(
    (params: GetMainMenuItemsParams) => {
      return filterByColumnMainMenuItems({
        params,
        colIDToFilterBuilderKey,
        openClause,
        mixpanel,
      });
    },
    [mixpanel, openClause]
  );
  const { getContextMenuItems, dialogs } = useTradeMenu({
    openClause,
    filterableProperties,
    generateOrderDetailsRoute,
    showOpenOrderDetails,
  });

  const handleExportTrades = useCallback(() => {
    mixpanel.track(MixpanelEvent.ExportTrades);
    const serverFilter = {
      ...(onlyServerFilterKeys(filteredTrades.filter) ?? {}),
      ...(orderID != null && { OrderID: orderID }),
    };
    const stringFilters = keys(serverFilter)
      .map(key => {
        const value: string[] = serverFilter[key];
        if (isEmpty(value)) {
          return undefined;
        }
        switch (key) {
          case 'StartDate':
          case 'EndDate':
            return undefined;
          case 'Symbols':
            return `Symbol:${value}`;
          case 'SubAccounts':
            // Map sub account Names to their IDs to make this API happy
            return `SubAccountID:${value
              .map(subAcctName => subAccountsByName?.get(subAcctName)?.SubaccountID || undefined)
              .compact()}`;
        }
        return `${key}:${value}`;
      })
      .compact();
    const query = {
      startDate: formattedDateForSubscription(serverFilter.StartDate),
      endDate: formattedDateForSubscription(serverFilter.EndDate || parseDate(null)),
      orderBy: '-TransactTime',
      filter: stringFilters.length > 0 ? stringFilters : undefined,
    };
    if (!query.startDate) {
      // Backend doesn't like an empty startDate, but is fine if we delete it
      delete query.startDate;
    }
    exportOMSTrades(user.OrgApiEndpoint, query);
  }, [mixpanel, filteredTrades.filter, orderID, user.OrgApiEndpoint, subAccountsByName]);

  const { showBlotterPauseButton } = useFeatureFlag();

  const blotterTable = useWsBlotterTable({
    initialRequest: {
      name: TRADE,
      tag: blotterTag,
      sort_by: '-TransactTime',
      ...(orderID != null && { OrderID: orderID }),
    },
    initialFilter: onlyServerFilterKeys(initialFilter),
    initialSort: persistedBlotterTable.initialSort,
    rowID: 'TradeID',
    density: BlotterDensity.Compact,
    rowSelection: 'multiple',
    clientLocalFilter,
    columns: persistedBlotterTable.columns,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    onSortChanged: persistedBlotterTable.onSortChanged,
    getContextMenuItems,
    onRowSelectionChanged: handleRowSelectionChanged,
    getExtraMainMenuItems,
    quickSearchParams: {
      entitySearchKeys: ENTITY_SEARCH_KEYS,
    },
    pauseParams: {
      showPauseButton: showBlotterPauseButton,
    },
    startingRowLimit: getConfig(OrgConfigurationKey.TradeRowsMax, DEFAULT_MAX_ROWS),
  });

  const handleManualTrade = () => {
    mixpanel.track(MixpanelEvent.OpenManualTradeForm);
    dispatch(prime());
    dispatch(openView(OMSView.ManualTradeForm));
  };

  const handleExport = useCallback(() => {
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'Trades',
        tabLabel,
      }),
    });
  }, [blotterTable, tabLabel]);

  /**
   * When the configured filter changes (state owned by useTradeFilter), tell WSBlotterTable about
   * the server keys of the filter but not the locally evaluated keys as they might break the backend.
   */
  useEffect(() => {
    if (filteredTrades.filter) {
      const serverFilter = onlyServerFilterKeys(filteredTrades.filter);
      if (!isEqual(blotterTable.filter, serverFilter)) {
        blotterTable.onFilterChanged(serverFilter);
      }
    }
  }, [blotterTable, filteredTrades.filter]);

  const handleCloneTab = useCallback(() => {
    mixpanel.track(MixpanelEvent.CloneTab);
    onCloneTab?.(filteredTrades.filter, blotterTable.getColumns().map(columnToColumnState));
  }, [blotterTable, filteredTrades.filter, onCloneTab, mixpanel]);

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  const handleOnApiOrdersChange = useDynamicCallback((value: boolean) => {
    mixpanel.track(MixpanelEvent.ApiOrdersToggle, { [MixpanelEventProperty.Enabled]: value });
    changeFilter(curr => ({ ...curr, HideApiCalls: !value }));
  });

  return (
    <>
      <BlotterTableFilters
        {...filterBuilderAccordion}
        {...blotterTableFilterProps}
        {...blotterTable.blotterTableFiltersProps}
        showDateRangePicker={showDateRangePicker}
        suffix={
          <BlotterTableExtrasMenu {...extrasMenuPopover}>
            {showAPIOrdersToggle && (
              <>
                <Toggle
                  label="API Orders"
                  size={FormControlSizes.Small}
                  tooltip="Include trades from orders submitted through the API in the results."
                  checked={!filteredTrades.filter.HideApiCalls}
                  onChange={handleOnApiOrdersChange}
                />
              </>
            )}
            {onCloneTab && (
              <Button
                startIcon={IconName.Duplicate}
                variant={ButtonVariants.Default}
                size={FormControlSizes.Small}
                onClick={handleCloneTab}
              >
                Clone Tab
              </Button>
            )}
            {showCreateManualTrade && isAuthorized(ACTION.SUBMIT_ORDER) && (
              <Button
                startIcon={IconName.Plus}
                variant={ButtonVariants.Default}
                size={FormControlSizes.Small}
                onClick={() => {
                  extrasMenuPopover.close();
                  handleManualTrade();
                }}
              >
                Manual Trade
              </Button>
            )}
            <Button
              startIcon={IconName.DocumentUpload}
              variant={ButtonVariants.Default}
              size={FormControlSizes.Small}
              data-testid="export-button"
              onClick={() => {
                mixpanel.track(MixpanelEvent.ExportRowsModalOpen);
                exportDialog.open();
              }}
            >
              Export
            </Button>
          </BlotterTableExtrasMenu>
        }
      />
      <WSBlotterTableMaxRecordsReachedWarning
        {...blotterTable.paginationLimit}
        getTimestamp={trade => trade.TransactTime}
      />
      <BlotterTable {...blotterTable} />
      <SummaryLine
        homeCurrency={homeCurrency}
        rows={selectedTrades}
        getQuantity={getTradeQuantity}
        getAmount={getTradeAmount}
      />
      <ExportDialog
        {...exportDialog}
        handleExportLocalData={handleExport}
        handleExportServerData={handleExportTrades}
      />
      {dialogs}
    </>
  );
};

function onlyServerFilterKeys(filter: BlotterTableTradeFilter | undefined) {
  if (!filter) {
    return filter;
  }
  return pick(filter, [
    'StartDate',
    'EndDate',
    'Statuses',
    'SubAccounts',
    'Symbols',
    'HideApiCalls',
    'OrderID',
    'RFQID',
    'Markets',
  ]);
}
