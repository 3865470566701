import {
  AccordionGroup,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  Box,
  EMPTY_ARRAY,
  HStack,
  usePortal,
  VStack,
} from '@talos/kyoko';
import { noop } from 'lodash';
import { FilteredOrders } from './FilteredOrders';
import { ORDERS_BLOTTER_PREFIX } from './tokens';

/** Simplified version of the Orders components that excludes the Inner Tabs */
export const RecentOrders = ({ blotterId }: { blotterId: string }) => {
  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);
  return (
    <VStack alignItems="stretch" gap="spacingTiny" h="100%" w="100%">
      <HStack
        minHeight="40px"
        w="100%"
        alignItems="center"
        justifyContent="right"
        gap="spacingComfortable"
        background="backgroundContent"
      >
        <Box ref={filtersContainerRef} px="spacingDefault" />
      </HStack>{' '}
      <AccordionGroup key={blotterId}>
        <FilteredOrders
          key={blotterId}
          blotterID={`${ORDERS_BLOTTER_PREFIX}/${blotterId}`}
          defaultFilter={EMPTY_ARRAY}
          onCloneTab={noop}
          initialIsOpen={false}
        />
      </AccordionGroup>
    </VStack>
  );
};
