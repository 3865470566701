import { VStack } from '@talos/kyoko';
import { PortfolioControlHeader } from './PortfolioControlHeader';
import { PortfolioWorkspaceHeader } from './PortfolioWorkspaceHeader';
import { usePortfolioRouting } from './usePortfolioRouting';

export const PortfolioDashboardHeader = () => {
  const { selectedTab } = usePortfolioRouting();
  return (
    <VStack flex="none" gap="spacingTiny">
      <PortfolioWorkspaceHeader selectedTab={selectedTab} />
      <PortfolioControlHeader />
    </VStack>
  );
};
