import {
  type AggregationWithAccounts,
  Flex,
  FormGroup,
  type FormGroupProps,
  Icon,
  IconName,
  MarketSelectionsList,
  SearchSelect,
  type SearchSelectProps,
  type SearchSelectRef,
  useSecuritiesContext,
} from '@talos/kyoko';
import { compact } from 'lodash';
import { useMemo } from 'react';

export function AggregationsSelector(
  props: SearchSelectProps<AggregationWithAccounts> & { ref?: React.ForwardedRef<SearchSelectRef> } & {
    showMarketSelections?: boolean;
    marketSelectionsHeight?: string;
    /** If provided, will wrap the selector within a form group. */
    formGroupProps?: FormGroupProps;
    /** If provided, will filter markets based on the security.Markets */
    symbol: string | undefined;
  }
) {
  const { securitiesBySymbol } = useSecuritiesContext();
  const { selection, showMarketSelections = true, marketSelectionsHeight, formGroupProps, symbol } = props;
  const security = symbol ? securitiesBySymbol.get(symbol) : undefined;

  const selections = useMemo(() => {
    if (security?.Markets) {
      const allowedMarketSet = new Set(security?.Markets);
      const selectionList = selection?.Accounts
        ? Array.from(selection.Accounts.values()).filter(
            aggMarket => aggMarket.Market && allowedMarketSet.has(aggMarket.Market)
          )
        : [];

      return compact(selectionList.map(aggMarket => aggMarket.MarketAccount));
    }

    return selection?.Accounts ? Array.from(selection.Accounts.keys()) : [];
  }, [selection, security?.Markets]);

  const select = <SearchSelect {...props} prefix={<Icon icon={IconName.CommandCmd} color="purple.default" />} />;

  if (formGroupProps) {
    return (
      <>
        <FormGroup {...formGroupProps}>{select}</FormGroup>
        {showMarketSelections && <MarketSelectionsList h={marketSelectionsHeight} selections={selections} />}
      </>
    );
  }

  return (
    <>
      <Flex gap="spacingDefault" flexDirection="column">
        {select}
        {showMarketSelections && <MarketSelectionsList h={marketSelectionsHeight} selections={selections} />}
      </Flex>
    </>
  );
}
