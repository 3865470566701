import { AccordionGroup, HStack, LookbackOption, VStack } from '@talos/kyoko';
import { useMemo } from 'react';
import { FilteredTransfersWrapper, type TransfersTabProps } from './FilteredTransfersWrapper';
import { TRANSFERS_BLOTTER_PREFIX } from './tokens';
import { TransfersFilterRow } from './TransfersFilterRow';
import { useTransfersColumns } from './useTransfersColumns';

/** Simplified version of the Transfers components that excludes the Inner Tabs */
export const RecentTransfers = ({ blotterId }: { blotterId: string }) => {
  const defaultColumns = useTransfersColumns();

  const newTabDefaults = useMemo(() => {
    return {
      defaultFilter: {
        _start: LookbackOption.Past30Days,
      },
      defaultColumns,
    } satisfies TransfersTabProps;
  }, [defaultColumns]);

  return (
    <VStack alignItems="stretch" gap="spacingTiny" h="100%" w="100%">
      <HStack
        minHeight="40px"
        w="100%"
        pr="spacingDefault"
        alignItems="center"
        justifyContent="right"
        gap="spacingComfortable"
        background="backgroundContent"
      >
        <TransfersFilterRow showNewTransfer={true} />
      </HStack>
      <AccordionGroup key={blotterId}>
        <FilteredTransfersWrapper
          key={blotterId}
          tabId={blotterId}
          blotterID={`${TRANSFERS_BLOTTER_PREFIX}/${blotterId}`}
          defaultColumns={newTabDefaults.defaultColumns}
          defaultFilter={newTabDefaults.defaultFilter}
          onCloneTab={() => ({})}
          initialIsOpen={false}
        />
      </AccordionGroup>
    </VStack>
  );
};
