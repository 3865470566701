import {
  BlotterTable,
  FormControlSizes,
  NumberVariants,
  SubAccountReconMatchStatusEnum,
  useBlotterTable,
  type Column,
  type ColumnDef,
  type SubAccountReconMatch,
} from '@talos/kyoko';
import type { ColDef, GridOptions, ICellRendererParams, RowClassParams, ValueFormatterParams } from 'ag-grid-community';
import { useMemo } from 'react';
import {
  RECON_MATCH_PROVIDER_TALOS,
  ReconMatchMarketAccountRow,
  ReconMatchSubAccountRow,
  type ReconMatchRow,
} from './reconMatchRows';
import { BlotterStylesWrapper } from './styles';
import type { BreakDetailsBlotterTabProps } from './types';
import { useReconMatchesObs } from './useReconMatchesObs';

interface BreakDetailsBlotterProps {
  tab: BreakDetailsBlotterTabProps;
  checkpointID: string;
  onClickResolve: (match: SubAccountReconMatch) => void;
}

function getRowClass(params: RowClassParams<ReconMatchRow>) {
  const data = params.node.data;
  return data instanceof ReconMatchMarketAccountRow ? 'row-lighten' : undefined;
}

export const BreakDetailsBlotter = ({ tab, onClickResolve, checkpointID }: BreakDetailsBlotterProps) => {
  const dataObservable = useReconMatchesObs(tab.defaultFilter, checkpointID);
  const columns = useReconMatchesColumns(onClickResolve);

  const blotterTable = useBlotterTable({
    dataObservable,
    rowID: 'rowID' satisfies keyof ReconMatchRow,
    columns,
    rowSelection: 'multiple',
    ...({ suppressRowTransform: true, getRowClass } satisfies GridOptions),
  });

  return (
    <BlotterStylesWrapper h="100%" data-testid="break-details-blotter">
      <BlotterTable {...blotterTable} />
    </BlotterStylesWrapper>
  );
};

// This callback will expand any invocations from ReconMatchSubAccountRow nodes to be 2 rows tall.
const doubleHeightRowSpanFn: ColDef<ReconMatchRow>['rowSpan'] = params => {
  const data: ReconMatchRow | undefined = params.node?.data;
  return data instanceof ReconMatchSubAccountRow ? 2 : 1;
};

// We need a way to distinguish double-height cells such that we can then apply special css rules to them.
const doubleHeightRowSpanClassRules: ColDef<ReconMatchRow>['cellClassRules'] = {
  'cell-span': params => {
    const data: ReconMatchRow | undefined = params.node?.data;
    return data instanceof ReconMatchSubAccountRow;
  },
};

const doubleHeightColDefProps = {
  cellClassRules: doubleHeightRowSpanClassRules,
  rowSpan: doubleHeightRowSpanFn,
} as const;

const useReconMatchesColumns = (onClickResolve: (row: ReconMatchSubAccountRow) => void) => {
  const columns: Column[] = useMemo(() => {
    return [
      {
        sortable: false,
        ...doubleHeightColDefProps,
        type: 'text',
        field: 'EventType',
        title: 'Ledger Event',
      },
      {
        sortable: false,
        ...doubleHeightColDefProps,
        type: 'asset',
        field: 'Asset',
        params: {
          colorful: true,
        },
      },
      {
        sortable: false,
        ...doubleHeightColDefProps,
        type: 'reconMatchStatus',
        field: 'Status',
      },
      {
        sortable: false,
        title: 'Transact Time',
        field: 'TransactTime',
        ...doubleHeightColDefProps,
        type: 'date',
        params: {
          milliseconds: true,
        },
      },
      {
        sortable: false,
        ...doubleHeightColDefProps,
        title: 'Break Amount',
        type: 'size',
        field: 'breakAmount',
        params: {
          currencyField: 'Asset' satisfies keyof ReconMatchRow,
          getSentiment: (params: ICellRendererParams<ReconMatchRow>) => {
            return params.node.data?.hasBreak ? NumberVariants.Warning : undefined;
          },
        },
      },
      {
        sortable: false,
        ...doubleHeightColDefProps,
        title: 'Sub Account(s)',
        field: 'subAccounts',
        type: 'subAccountNames',
      },
      {
        sortable: false,
        ...doubleHeightColDefProps,
        title: 'Market Account',
        field: 'marketAccount',
        type: 'marketAccount',
      },
      {
        sortable: false,
        title: 'Provider',
        field: 'provider',
        // Need to make this a custom column since the provider is either a Market.Name or "Talos"
        // You could just use the Market column and rely on "Talos" not getting a hit in the marketsByName lookup but
        // that seems hacky... I prefer this.
        type: 'custom',
        params: {
          valueFormatter: ({ value, context }: ValueFormatterParams<ReconMatchRow, ReconMatchRow['provider']>) => {
            if (value == null) {
              return '';
            }

            // value is either "Talos" or it is a Market.Name.
            if (value === RECON_MATCH_PROVIDER_TALOS) {
              return value;
            }

            return context.current.marketDisplayNameByName?.get(value) ?? value;
          },
        },
      },

      {
        sortable: false,
        title: 'Amount',
        type: 'size',
        field: 'amount',
        params: {
          currencyField: 'Asset' satisfies keyof ReconMatchRow,
        },
      },
      {
        sortable: false,
        title: 'Avg Cost',
        type: 'price',
        field: 'avgCost',
        params: {
          quoteCurrencyField: 'avgCostCurrency' satisfies keyof ReconMatchRow,
        },
      },
      {
        sortable: false,
        title: 'Comments',
        type: 'textArray',
        field: 'comments',
      },
      {
        sortable: false,
        type: 'button',
        id: 'resolve-button',
        suppressColumnsToolPanel: true,
        pinned: 'right',
        frozen: true,
        ...doubleHeightColDefProps,
        width: 70,
        params: {
          onClick: (params: ICellRendererParams<ReconMatchRow>) => {
            const data = params.node.data;
            if (data instanceof ReconMatchSubAccountRow) {
              onClickResolve(data);
            }
          },
          disabled: params => {
            const data: ReconMatchRow | undefined = params.node.data;
            if (!data) {
              return true;
            }

            // disable if theres no break on the entity, or if we've been resolved
            return !data.hasBreak || data.Status === SubAccountReconMatchStatusEnum.Resolved;
          },

          size: FormControlSizes.Small,
          children: 'Resolve',
        },
      },
    ] satisfies ColumnDef<ReconMatchRow>[];
  }, [onClickResolve]);

  return columns;
};
