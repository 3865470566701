import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import styled, { createGlobalStyle } from 'styled-components';
import { blotterStyles } from '../BlotterTable/styles';
import type { BlotterDensity } from '../BlotterTable/types';

// This is needed because popup editors are attached to document.body
export const GlobalStyle = createGlobalStyle`
  .ag-theme-balham-dark .ag-popup-editor {
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;
    height: auto !important;
  }
`;

export const AgGridStyleWrapper = styled.div<{
  background?: string;
  density?: BlotterDensity;
}>`
  &.ag-theme-balham-dark {
    // See https://www.ag-grid.com/javascript-grid/themes-customising/#full-list-of-theme-parameters

    ${({ theme, background, density }) => blotterStyles(theme, density, background)}
  }
`;
