import type { SubAccountReconMatch } from '@talos/kyoko';
import type { ReconBreak } from './BreakResolution/types';
import type { ReconAssetRow } from './ReconOverview/blotter/reconCheckpointRows';

/**
 * Create a ReconBreak, resolvable in the ResolutionDrawer, from a SubAccountReconMatch
 *
 * Because the Match itself might be missing sub accounts entirely, we need to also pass in the contextual sub accounts
 * from the Checkpoint this Match is a part of.
 */
export function reconMatchToBreak(match: SubAccountReconMatch, checkpointSubAccounts: string[]): ReconBreak {
  return {
    checkpointID: match.CheckpointID,
    matchID: match.ID,
    asset: match.Asset,
    status: match.Status,
    breakAmount: match.breakAmount,
    hasBreak: match.hasBreak,
    timestamp: match.TransactTime,
    // only use the match sub accounts if they're populated, otherwise the checkpoint's
    subAccounts: match.subAccounts != null && match.subAccounts.length > 0 ? match.subAccounts : checkpointSubAccounts,
    marketAccount: match.MarketAccountEvent?.MarketAccount,
    type: match.EventType,
  };
}

export function reconAssetRowToBreak(row: ReconAssetRow): ReconBreak {
  return {
    checkpointID: row.checkpointID,
    asset: row.Asset,
    status: row.Status,
    breakAmount: row.breakAmount,
    hasBreak: row.hasBreak,
    timestamp: row.LastUpdateTime,
    subAccounts: row.SubAccounts,
    type: 'Checkpoint',
  };
}
