import { darken, lighten, rgba } from 'polished';
import type { DefaultTheme } from 'styled-components';
import { css, keyframes } from 'styled-components';
import { parseColor } from './themes/utils';

/**
 *
 * @param theme The styled-components theme
 * @param color The color you wish to apply some darken or lighten to
 * @param diff The amount, 0 to 1, you wish to apply darken or lighten with. Defaults to 0.05.
 * @returns a new string color
 */
export function hoverColor(theme: Pick<DefaultTheme, 'type'>, color: string, diff = 0.05): string {
  if (theme.type === 'light') {
    return darken(diff, color);
  } else {
    return lighten(diff, color);
  }
}

/**
 * Adjust the opacity of a color by setting the alpha value of an opaque color
 * @param amount The opacity level for the color
 * @param color The color to adjust
 * @returns a color string with the correct alpha value
 */
export function setAlpha(amount: number, color: string) {
  return rgba(color, amount);
}

const hexRegex = /#[a-f\d]{3}(?:[a-f\d]?|(?:[a-f\d]{3}(?:[a-f\d]{2})?)?)\b/;
const hslRegex =
  /hsla?\((?:(-?\d+(?:deg|g?rad|turn)?),\s*((?:\d{1,2}|100)%),\s*((?:\d{1,2}|100)%)(?:,\s*((?:\d{1,2}|100)%|0(?:\.\d+)?|1))?|(-?\d+(?:deg|g?rad|turn)?)\s+((?:\d{1,2}|100)%)\s+((?:\d{1,2}|100)%)(?:\s+((?:\d{1,2}|100)%|0(?:\.\d+)?|1))?)\)/;
const rgbRegex =
  /rgba?\((?:(25[0-5]|2[0-4]\d|1?\d{1,2}|(?:\d{1,2}|100)%),\s*(25[0-5]|2[0-4]\d|1?\d{1,2}|(?:\d{1,2}|100)%),\s*(25[0-5]|2[0-4]\d|1?\d{1,2}|(?:\d{1,2}|100)%)(?:,\s*((?:\d{1,2}|100)%|0(?:\.\d+)?|1))?|(25[0-5]|2[0-4]\d|1?\d{1,2}|(?:\d{1,2}|100)%)\s+(25[0-5]|2[0-4]\d|1?\d{1,2}|(?:\d{1,2}|100)%)\s+(25[0-5]|2[0-4]\d|1?\d{1,2}|(?:\d{1,2}|100)%)(?:\s+((?:\d{1,2}|100)%|0(?:\.\d+)?|1))?)\)/;

/**
 * Check if a given string is usable as a CSS color
 * @param color the color you want to validate
 * @returns true if the string is a valid color string
 */
export const isAdjustableColor = (color: string) => {
  const s = new Option().style;
  s.color = color;

  if (s.color === '' || color.includes('url') || color.includes('gradient')) {
    return false;
  }

  if (color.length > 0) {
    if (hexRegex.test(color) || hslRegex.test(color) || rgbRegex.test(color)) {
      return true;
    }
  }

  return false;
};

const loading = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -200% -200%;
  }
`;

/** Get an animated background for a loading container */
export function getLoadingAnimation(
  theme: DefaultTheme,
  isLoading: boolean | undefined,
  background: string,
  darkerBackground?: string
) {
  return isLoading
    ? css`
        background: linear-gradient(
          270deg,
          ${parseColor(theme, background) ?? background},
          ${darkerBackground ?? darken(0.25, parseColor(theme, background) ?? background)},
          ${parseColor(theme, background) ?? background}
        );
        background-position: 0% 0%;
        background-size: 200%;
        animation: ${loading} 2s linear infinite;
      `
    : `background: ${parseColor(theme, background) ?? background};`;
}
