import {
  AccordionGroup,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  Box,
  formattedDateForSubscription,
  HStack,
  usePortal,
  VStack,
} from '@talos/kyoko';
import { subDays } from 'date-fns';
import type { TradeTabProps } from '.';
import { DEFAULT_TRADE_COLUMNS } from './columns';
import { FilteredTrades } from './FilteredTrades';
import { TRADES_BLOTTER_PREFIX } from './tokens';

const RECENT_TAB_DEFAULTS: TradeTabProps = {
  defaultFilter: {
    StartDate: formattedDateForSubscription(subDays(new Date(), 1)),
  },
  defaultColumns: DEFAULT_TRADE_COLUMNS,
};
/** Simplified version of the Trades components that excludes the Inner Tabs */
export const RecentTrades = ({ blotterId }: { blotterId: string }) => {
  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);
  return (
    <VStack alignItems="stretch" gap="spacingTiny" h="100%" w="100%">
      <HStack
        minHeight="40px"
        w="100%"
        alignItems="center"
        justifyContent="right"
        gap="spacingComfortable"
        background="backgroundContent"
      >
        <Box ref={filtersContainerRef} px="spacingDefault" />
      </HStack>{' '}
      <AccordionGroup key={blotterId}>
        <FilteredTrades
          key={blotterId}
          blotterPersistID={`${TRADES_BLOTTER_PREFIX}/${blotterId}`}
          defaultColumns={RECENT_TAB_DEFAULTS.defaultColumns}
          defaultFilter={RECENT_TAB_DEFAULTS.defaultFilter}
          onCloneTab={() => ({})}
          initialIsOpen={false}
          showCreateManualTrade={true}
          showAPIOrdersToggle={true}
        />
      </AccordionGroup>
    </VStack>
  );
};
