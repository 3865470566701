import {
  abbreviate,
  BaseChart,
  Box,
  Flex,
  Text,
  ThemeTypes,
  unboundedAbbreviate,
  useDefaultChartOptions,
  useHighchartsRef,
  VStack,
} from '@talos/kyoko';
import { useDisplaySettings } from 'providers/DisplaySettingsProvider';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { filterAndSortChartDisplaySeries } from './filterStackedChartDisplaySeries';

const ExposureDisplay = ({
  show,
  numberColor,
  currencyColor,
  value,
  homeCurrency,
}: {
  show: boolean;
  value: string | undefined;
  homeCurrency: string;
  numberColor: string;
  currencyColor: string;
}) => {
  return !show ? null : (
    <>
      <Text fontWeight={500} color={numberColor}>
        {value ?? ''}
      </Text>{' '}
      <Text color={currencyColor}>{homeCurrency}</Text>
    </>
  );
};

export type StackedChartDataItem = {
  /** Stacked item display name */
  asset: string;
  /** Stacked item description */
  assetDescription: string;
  /** Stacked item value */
  value: number;
  /** Color for the stacked box item */
  color: string | undefined;
};

export function InnerStackedChart({
  data,
  isDataLoading,
}: {
  isDataLoading: boolean;
  data: StackedChartDataItem[] | undefined;
}) {
  const { chartRef, setChartObject } = useHighchartsRef({});
  const { homeCurrency } = useDisplaySettings();
  const theme = useTheme();
  const chartSeries = useMemo<{
    chartSeriesData: Highcharts.SeriesBarOptions[];
    topLevelExposures: { long: string; short: string; net: string } | undefined;
  }>(() => {
    if (!data) {
      return { chartSeriesData: [], topLevelExposures: undefined };
    }

    const analysisData = data.filter(item => item.value !== 0);
    const topLevelExposureData = calcExposures(data);
    const chartData = filterAndSortChartDisplaySeries(analysisData);

    const chartSeriesData = chartData.map(item => {
      const value = item.value ?? 0;
      const color = item.color;
      const dataValue = {
        y: value,
        label: item.assetDescription,
      };
      // place the data in the correct order for the stacked chart, in order to get the distinct separated long/short effect
      const data = value < 0 ? [0, dataValue] : [dataValue, 0];
      return {
        type: 'bar',

        dataLabels: {
          enabled: true,
          shadow: theme.type === ThemeTypes.dark,
          align: 'center',
          style: {
            textOutline: 'none',
            fontWeight: 'normal',
            fontSize: '0.8em',
            fontFamily: theme.fontFamily,
            color: theme.colorTextImportant,
          },
          formatter: function () {
            const value = this.point.y ?? 0;
            if (value === 0) {
              return '';
            }
            const dataValue = `${this.series.name}<br/>${unboundedAbbreviate(value, {
              precision: 2,
            })}`;

            return dataValue;
          },
          verticalAlign: 'middle',
        },
        data,
        color,
        name: item.asset,
      } satisfies Highcharts.SeriesBarOptions;
    });
    return {
      chartSeriesData,
      topLevelExposures: {
        long: `${unboundedAbbreviate(topLevelExposureData.long, {
          precision: 2,
        })}`,
        short: `${unboundedAbbreviate(topLevelExposureData.short, {
          precision: 2,
        })}`,
        net: `${unboundedAbbreviate(topLevelExposureData.net, {
          precision: 2,
        })}`,
      },
    };
  }, [data, theme]);

  // Initial static options for Highcharts.Options
  // - This could not be moved out of the component as it relies on the chartRef and theme, but it should be stable
  // - The separation avoids unnecessary function creation, which prevents unwanted tooltip flickering/clearing
  const staticOptions = useMemo(() => {
    const shortColor = theme.type === ThemeTypes.dark ? theme.colors.blue.lighten : theme.colors.blue.default;
    return {
      chart: {
        type: 'bar',
        events: {
          render: function () {
            const series = this.series;
            // Ensure tooltips are positioned correctly in relation to the individual bars
            // this is part 1 of 2, as tooltip->positioner is the other part handles the final positioning
            // in relation to the chart and how it relates to the content of the tooltip
            series.forEach(s => {
              s.points.forEach(p => {
                // @ts-expect-error - incomplete TS definitions
                const { negative, tooltipPos }: { negative: boolean; tooltipPos: [number, number] | undefined } = p;
                if (tooltipPos) {
                  if (negative) {
                    tooltipPos[0] += p.shapeArgs!.height / 2;
                    tooltipPos[1] -= p.shapeArgs!.width * 1.5;
                  } else {
                    tooltipPos[0] -= p.shapeArgs!.height / 2;
                    tooltipPos[1] += p.shapeArgs!.width * 1;
                  }
                }
                // @ts-expect-error - incomplete TS definitions
                p.tooltipPos = tooltipPos;
              });
            });
          },
        },
      },
      xAxis: {
        visible: false,
        accessibility: {
          description: '',
        },
        maxPadding: 0.1,
        minPadding: 0.1,
      },
      yAxis: {
        reversedStacks: false,

        opposite: false,
        plotLines: [
          {
            value: 0,
            color: theme.colors.gray['090'],
            width: 1,
            zIndex: 10,
          },
        ],
        title: {
          text: `Position (${homeCurrency})`,
        },
        maxPadding: 0.05,
        minPadding: 0.05,
        labels: {
          enabled: true,
          align: 'center',
          formatter: function () {
            if (typeof this.value === 'string') {
              return '';
            }
            const style = this.value < 0 ? `color: ${shortColor}` : '';
            return `<span style="${style}">${unboundedAbbreviate(this.value, {
              precision: 2,
            })}</span>`;
          },
        },

        stackLabels: {
          enabled: false,
          style: {
            color: theme.colorTextImportant,
            textOutline: 'none',
            fontSize: '0.8em',
            fontFamily: theme.fontFamily,
          },
          formatter: function () {
            return this.total > 0
              ? `Long: ${abbreviate(this.total, {
                  precision: 2,
                })}`
              : `Short: ${abbreviate(Math.abs(this.total), {
                  precision: 2,
                })}`;
          },
        },
        startOnTick: false,
        endOnTick: false,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          stacking: 'normal',
          // width cleanly divisible by 3 to ensure even spacing
          borderWidth: 1.5,
          boostBlending: 'darken',
          borderColor: theme.backgroundContent,
          groupPadding: 0,
          pointPadding: 0.01,
          dataLabels: {
            enabled: true,
          },
          accessibility: {
            exposeAsGroupOnly: true,
          },
        },
      },

      tooltip: {
        borderWidth: 0,
        backgroundColor: 'rgba(255,255,255,0)',
        useHTML: true,
        outside: true,
        formatter: function () {
          const value = this.point.y ?? 0;
          const dataValue = unboundedAbbreviate(value, {
            precision: 2,
          });
          const style = value < 0 ? `color: ${shortColor}` : '';
          const description = this.point.options?.label ? `<br/>${this.point.options.label}` : '';
          return `<div style="background: ${theme.colors.gray['010']}; margin: 0px; padding: 4px; border: 1px solid ${theme.colors.gray['050']}">
            <strong>${this.series.name}</strong>${description}<br/>
            Value: <span style="${style}">${dataValue}</span>
            </div>`;
        },
        // part 2 of 2 for tooltip positioning, this is the final positioning in relation to the chart
        positioner: function (labelWidth, labelHeight, point) {
          let tooltipX = point.plotX;
          const tooltipY = point.plotY;

          // adjust tooltip position to horizontally center over the bar
          // based on its content (plus some necessary padding)
          tooltipX -= labelWidth / 2 - 10;

          // ensure tooltip doesn't go off the chart
          if (chartRef.current) {
            tooltipX = Math.max(chartRef.current.plotLeft + 10, tooltipX);
            const chartLeft2 = chartRef.current.plotLeft + chartRef.current.plotWidth - labelWidth - 10;
            tooltipX = Math.min(tooltipX, chartLeft2);
          }

          return { x: tooltipX, y: tooltipY };
        },
      },
    } satisfies Highcharts.Options;
  }, [chartRef, homeCurrency, theme]);

  const options = useMemo(() => {
    return {
      ...staticOptions,
      series: chartSeries.chartSeriesData,
    } satisfies Highcharts.Options;
  }, [chartSeries.chartSeriesData, staticOptions]);

  const defaultChartOptions = useDefaultChartOptions();
  const defaultOverridesToUse: Highcharts.Options = {
    ...defaultChartOptions,
    xAxis: {},
  };

  const showTotals = chartSeries.chartSeriesData.length > 0;
  return (
    <VStack w="100%" h="100%" position="relative">
      <VStack flex="none" w="100%" alignItems="flex-start" position="relative" p="spacingDefault">
        <Flex pt="spacingDefault" w="100%" fontSize="16px" justifyContent="space-between">
          <Text>
            Short Position:{' '}
            <ExposureDisplay
              show={showTotals}
              value={chartSeries.topLevelExposures?.short}
              homeCurrency={homeCurrency}
              numberColor={theme.colors.red.lighten}
              currencyColor={theme.colors.red.default}
            />
          </Text>
          <Text>
            Net Position:{' '}
            <ExposureDisplay
              show={showTotals}
              value={chartSeries.topLevelExposures?.net}
              homeCurrency={homeCurrency}
              numberColor={theme.colors.gray['100']}
              currencyColor={theme.colors.gray['090']}
            />
          </Text>
          <Text>
            Long Position:{' '}
            <ExposureDisplay
              show={showTotals}
              value={chartSeries.topLevelExposures?.long}
              homeCurrency={homeCurrency}
              numberColor={theme.colors.green.lighten}
              currencyColor={theme.colors.green.default}
            />
          </Text>
        </Flex>
      </VStack>
      <Box flex="1" w="100%" position="relative">
        <Box position="absolute" top="0" left="0" right="0" bottom="0">
          <BaseChart
            throttleResizing={0}
            onChartCreated={setChartObject}
            showOverlayWhenLoading
            isLoading={isDataLoading}
            options={options}
            defaultOverrides={defaultOverridesToUse}
            customNoDataText="No positions returned for this portfolio"
          />
        </Box>
      </Box>
    </VStack>
  );
}

function calcExposures(data: StackedChartDataItem[]) {
  return {
    long: data.filter(item => item.value > 0).reduce((acc, val) => acc + val.value, 0),
    short: data.filter(item => item.value < 0).reduce((acc, val) => acc + val.value, 0),
    net: data.reduce((acc, val) => acc + val.value, 0),
  };
}
