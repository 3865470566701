import {
  type ColDef,
  GroupCellRenderer,
  type GroupCellRendererParams,
  type ICellRendererParams,
  type ValueFormatterParams,
  type ValueGetterParams,
} from 'ag-grid-community';
import { useMemo } from 'react';
import { Text } from '../../Text';
import { autoGroupSortingDefaults } from '../columns/autoGroupSortingDefaults';
import { AGGRID_AUTOCOLUMN_ID } from '../types';
import type { TreeRow } from './TreeRow';

interface UseTreeGroupColumnDefParams {
  headerName?: ColDef['headerName'];
  width?: ColDef['width'];
}

/**
 * This hook helps you build your autoGroupColumnDef for tree blotters.
 *
 * The hook forces your row data type to extend the `TreeRow` type.
 */
export const useTreeGroupColumnDef = <TData extends TreeRow>({
  // Important to pick out the params individually here so we get proper change detection on the re-creation of autoGroupColumnDef
  headerName,
  width,
}: UseTreeGroupColumnDefParams): ColDef<TData> => {
  // Let's not re-compute autoGroupColumnDef unnecessarily

  const autoGroupColumnDef: ColDef<TData> = useMemo(
    () => ({
      colId: AGGRID_AUTOCOLUMN_ID,
      headerName: headerName ?? 'Group',
      width: width,
      editable: false,
      suppressColumnsToolPanel: false,
      cellRendererParams: {
        suppressCount: true,
      },
      sortable: true,
      pinned: 'left',

      ...autoGroupSortingDefaults,

      valueGetter: (params: ValueGetterParams<TData>) => {
        const data = params.node?.data;
        if (!data) {
          return undefined;
        }

        return data.groupColumnValueGetter(params);
      },
      valueFormatter: (params: ValueFormatterParams<TData>) => {
        const data = params.node?.data;
        if (!data) {
          return '';
        }

        return data.groupColumnFormattedValueGetter(params);
      },
      filterValueGetter: (params: ValueGetterParams<TData>) => {
        const data = params.node?.data;
        if (!data) {
          return undefined;
        }

        return data.groupColumnFilterValueGetter(params);
      },

      cellRendererSelector: (params: ICellRendererParams<TData>) => {
        // If its a pinned row (the totals row in this case), we opt out of the default group cell renderer and just do basic text to show "Totals"
        // Bit of a hard-coded case where we assume that .data has "groupColumnValue". This is set by the totals aggregation pipe separately.
        if (params.node.rowPinned) {
          return {
            params,
            component: (params: GroupCellRendererParams) => <Text>{params.data.groupColumnValue ?? params.value}</Text>,
          };
        }

        // Else if the node has defined a way to resolve its own cell renderer to be used, then we use that.
        // If not, just do the basic GroupCellRenderer setup
        const innerRenderer = params.node.data?.groupColumnInnerCellRenderer;
        if (innerRenderer) {
          return {
            component: GroupCellRenderer,
            params: {
              ...params,
              innerRenderer,
            },
          };
        }

        return { params, component: GroupCellRenderer };
      },
    }),
    [headerName, width]
  );

  return autoGroupColumnDef;
};
