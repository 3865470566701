import type { ICellRendererParams } from 'ag-grid-community';
import { get } from 'lodash';
import styled, { keyframes } from 'styled-components';
import { HedgeControlStatusEnum, type IHedgePositionStatus } from '../../../types';
import { Flex, Grid } from '../../Core';
import { Icon, IconName } from '../../Icons';
import { iconByHedgePositionStatus, iconColorByHedgePositionStatus } from '../../PositionThresholdMeter';
import { Text } from '../../Text';
import { Tooltip } from '../../Tooltip';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

type AutoHedgeStatusColumnParams = {
  textField?: string;
};

export const autohedgeStatus: ColDefFactory<Column<AutoHedgeStatusColumnParams>> = column => {
  return {
    width: 100,
    ...baseColumn(column),
    cellRenderer: (params: ICellRendererParams<unknown, IHedgePositionStatus['HedgeControlStatus']>) => {
      const status = params.value;
      const text = column.params?.textField ? get(params.data, column.params.textField) : undefined;
      return <AutoHedgingStatus status={status} text={text} />;
    },
    comparator: (valueA: HedgeControlStatusEnum, valueB: HedgeControlStatusEnum) => {
      const sortA = AUTO_HEDGE_STATUS_SORT_ORDER[valueA] ?? -1;
      const sortB = AUTO_HEDGE_STATUS_SORT_ORDER[valueB] ?? -1;
      return sortA - sortB;
    },
  };
};

export const AutoHedgingStatus = ({ status, text }: { status: HedgeControlStatusEnum; text?: string }) => {
  const statusIcon = (
    <Flex gap="spacingSmall" color="colorTextImportant">
      <AutohedgingStatusIcon status={status} />
      <Text>{status}</Text>
    </Flex>
  );

  if (status === HedgeControlStatusEnum.Error && text) {
    return (
      <Tooltip tooltipTestID="autohedging-status-tooltip-content" tooltip={text}>
        {statusIcon}
      </Tooltip>
    );
  }

  return statusIcon;
};

const AutohedgingStatusIcon = ({ status }: { status: HedgeControlStatusEnum }) => {
  if (status === HedgeControlStatusEnum.Hedging) {
    return (
      <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr" placeItems="center">
        <Grid gridRow="-1/1" gridColumn="-1/1">
          <Icon size={7} icon={IconName.Check} color={iconColorByHedgePositionStatus[status]} />
        </Grid>
        <SpinningGrid gridRow="-1/1" gridColumn="-1/1">
          <Icon icon={IconName.CircleDotted} color={iconColorByHedgePositionStatus[status]} />
        </SpinningGrid>
      </Grid>
    );
  }

  return <Icon icon={iconByHedgePositionStatus[status]} color={iconColorByHedgePositionStatus[status]} />;
};

const spin = keyframes`
  0% {
    transform: rotate(0deg);
    opacity: 0.5;
  }
  30% {
    transform: rotate(180deg);
    opacity: 1;
  }
  50% {
    transform: rotate(180deg);
    opacity: 0.5;
  }
  80% {
    transform: rotate(270deg);
    opacity: 1;
  }
  100% {
    transform: rotate(270deg);
    opacity: 0.5;
  }
`;
/**
 * This is a spinning dotted circle that is used to indicate that the hedger is currently hedging.
 */
const SpinningGrid = styled(Grid)`
  animation: ${spin} 5s cubic-bezier(0.25, 0.75, 0.25, 0.75) infinite;
`;

const AUTO_HEDGE_STATUS_SORT_ORDER: Record<HedgeControlStatusEnum, number> = {
  [HedgeControlStatusEnum.Disabling]: 0,
  [HedgeControlStatusEnum.Disabled]: 1,
  [HedgeControlStatusEnum.Waiting]: 2,
  [HedgeControlStatusEnum.Hedging]: 3,
  [HedgeControlStatusEnum.Error]: 4,
  [HedgeControlStatusEnum.Complete]: 5,
} as const;
