import {
  AGGRID_AUTOCOLUMN_ID,
  BlotterTable,
  BlotterTableFilters,
  ButtonVariants,
  Flex,
  FormControlSizes,
  IconButton,
  IconName,
  type MinimalSubscriptionResponse,
  useBlotterTable,
  usePersistedBlotterTable,
  useTreeGroupColumnDef,
} from '@talos/kyoko';
import type { GridOptions } from 'ag-grid-community';
import { useCallback, useMemo } from 'react';
import type { Observable } from 'rxjs';
import { RECON_OVERVIEW_BLOTTER_PORTAL_ID, RECON_OVERVIEW_BLOTTER_PORTAL_ID_MODAL } from '../tokens';
import { ReconAssetRow, type SubAccountReconOverviewRow } from './reconCheckpointRows';
import {
  type ReconOverviewBlotterColumnIDs,
  useSubAccountReconOverviewBlotterColumns,
} from './useSubAccountReconOverviewBlotterColumns';
import { useSubAccountReconOverviewBlotterMenu } from './useSubAccountReconOverviewBlotterMenu';

interface SubAccountReconOverviewBlotterProps {
  dataObservable: Observable<MinimalSubscriptionResponse<SubAccountReconOverviewRow>>;
  /**
   * Called when the user clicks on the Deepdive/Details button of a ReconAssetRow.
   * Don't provide this if you don't want the blotter to render any of these buttons.
   */
  onViewCheckpointDetails?: (assetRow: ReconAssetRow) => void;
  /** Called when the user clicks on Resolve for a ReconAssetRow. Don't provide if you don't want to enable this for the user. */
  onResolveCheckpoint?: (assetRow: ReconAssetRow) => void;
  blotterID: string;
  /** Whether or not were rendering this blotter in the modal. Does some different stuff */
  isInModal?: boolean;
}

function getDataPath(data: SubAccountReconOverviewRow) {
  return data.dataPath;
}

// Matching column ids
const SEARCH_KEYS: string[] = ['Asset', 'Status', AGGRID_AUTOCOLUMN_ID] satisfies ReconOverviewBlotterColumnIDs[];

export const SubAccountReconOverviewBlotter = ({
  onViewCheckpointDetails,
  onResolveCheckpoint,
  blotterID,
  dataObservable,
  isInModal,
}: SubAccountReconOverviewBlotterProps) => {
  const defaultColumns = useSubAccountReconOverviewBlotterColumns();

  const persistedBlotterTable = usePersistedBlotterTable<SubAccountReconOverviewRow>(blotterID, {
    columns: defaultColumns,
    sort: `+${AGGRID_AUTOCOLUMN_ID}`,
  });

  const autoGroupColumnDef = useTreeGroupColumnDef<SubAccountReconOverviewRow>({
    headerName: 'Sub Accounts - Asset - Market Accounts',
    width: 300,
  });

  const {
    columns: menuColumns,
    getContextMenuItems,
    dialogs,
  } = useSubAccountReconOverviewBlotterMenu({
    onDetailsClicked: onViewCheckpointDetails,
    onResolveClicked: onResolveCheckpoint,
  });

  const columnsWithMenu = useMemo(
    () => [...persistedBlotterTable.columns, ...menuColumns],
    [persistedBlotterTable.columns, menuColumns]
  );

  const handleDoubleClickRow = useCallback(
    (data: SubAccountReconOverviewRow) => {
      if (data instanceof ReconAssetRow) {
        onViewCheckpointDetails?.(data);
      }
    },
    [onViewCheckpointDetails]
  );

  const blotterTable = useBlotterTable({
    dataObservable,
    rowID: 'rowID' satisfies keyof SubAccountReconOverviewRow,
    ...persistedBlotterTable,
    columns: columnsWithMenu,
    sort: persistedBlotterTable.initialSort,
    rowSelection: 'multiple',
    getContextMenuItems,
    groupDefaultExpanded: -1, // expand all rows by default
    quickSearchParams: {
      otherSearchKeys: SEARCH_KEYS,
    },
    onDoubleClickRow: handleDoubleClickRow,
    ...({ autoGroupColumnDef, treeData: true, getDataPath: getDataPath } satisfies GridOptions),
  });

  const { expandAllGroups, collapseAllLevelsGreaterThan } = blotterTable;

  return (
    <Flex flexDirection="column" w="100%" h="100%" data-testid="sub-account-recon-overview-blotter">
      <BlotterTableFilters
        {...blotterTable.blotterTableFiltersProps}
        showFilterBuilder={false}
        portalId={isInModal ? RECON_OVERVIEW_BLOTTER_PORTAL_ID_MODAL : RECON_OVERVIEW_BLOTTER_PORTAL_ID}
        prefix={
          <>
            <IconButton
              icon={IconName.ListExpand}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={expandAllGroups}
              data-testid="expand-all-button"
            />
            <IconButton
              icon={IconName.ListCollapse}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              // collapse all, but only levels greater than the root level (0)
              onClick={() => collapseAllLevelsGreaterThan(0)}
            />
          </>
        }
      />
      <BlotterTable {...blotterTable} />
      {dialogs}
    </Flex>
  );
};
