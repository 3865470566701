import {
  type AGGRID_AUTOCOLUMN_ID,
  type Column,
  type ColumnDef,
  EMPTY_ARRAY,
  getAgGridColId,
  NumberVariants,
  useDefaultColumns,
} from '@talos/kyoko';
import type { ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';
import type { SubAccountReconOverviewRow } from './reconCheckpointRows';

export type ReconOverviewBlotterColumnIDs = 'Asset' | 'Status' | typeof AGGRID_AUTOCOLUMN_ID;

export const useSubAccountReconOverviewBlotterColumns = (defaultColumns = EMPTY_ARRAY) => {
  const columnDefinitions: Map<string, Column> = useMemo(() => {
    const defsArr = [
      {
        type: 'asset',
        id: 'Asset' satisfies ReconOverviewBlotterColumnIDs,
        title: 'Instrument',
        hide: true,
        field: 'Asset',
        params: {
          colorful: true,
        },
      },
      {
        type: 'reconCheckpointStatus',
        width: 100,
        field: 'Status' satisfies ReconOverviewBlotterColumnIDs,
      },
      {
        type: 'reconCheckpointEvalStatus',
        width: 100,
        field: 'EvalStatus',
      },
      {
        type: 'number',
        field: 'Breaks',
        width: 100,
      },
      {
        type: 'size',
        field: 'SubAccountAmount',
        params: {
          currencyField: 'Asset',
        },
      },
      {
        type: 'size',
        field: 'MarketAccountAmount',
        params: {
          currencyField: 'Asset',
        },
        width: 180,
      },
      {
        type: 'size',
        field: 'BreakAmount',
        params: {
          currencyField: 'Asset',
          getSentiment: (params: ICellRendererParams<SubAccountReconOverviewRow>) => {
            return params.node?.data?.hasBreak ? NumberVariants.Warning : undefined;
          },
        },
      },
    ] satisfies ColumnDef<SubAccountReconOverviewRow>[];

    return new Map(defsArr.map(def => [getAgGridColId(def), def]));
  }, []);

  return useDefaultColumns(defaultColumns, columnDefinitions);
};
