import type { MarketAccountTypeEnum, MarketTypeEnum } from './types';

// to be replaced
export enum SubAccountReconCheckpointStatusEnum {
  Reconciled = 'Reconciled',
  Unreconciled = 'Unreconciled',
  Discarded = 'Discarded',
}

export enum SubAccountReconCheckpointEvalStatusEnum {
  Done = 'Done',
  Pending = 'Pending',
  InProgress = 'InProgress',
}

export interface SubAccountReconCheckpoint {
  ID: string;
  /**
   * The AccountsKey represents the  combined set of IDs of SubAccounts and MarketAccounts that are relevant to this SubAccountReconCheckpoint
   * It looks a bit weird, something like (1212,3434):(7878,4343) where its (mktacc1.id,mktacc2.id):(subacc1.id,subacc2.id)
   */
  AccountsKey: string;

  StartTime: string;
  EndTime: string;
  LastUpdateTime: string;
  Asset: string;
  Status: SubAccountReconCheckpointStatusEnum;
  EvalStatus: SubAccountReconCheckpointEvalStatusEnum;

  Unmatched: {
    Count: number;
    Amount: string;
  };

  SubAccountDetails: {
    Amount: string;
    SubAccounts: {
      SubAccount: string;
      Amount: string;
      Status: SubAccountReconCheckpointStatusEnum;
      Unmatched: {
        Amount: string;
        Count: number;
      };
    }[];
  };

  MarketAccountDetails?: {
    Amount: string;
    MarketAccounts: MarketAccountDetail[];
  };
}

export interface MarketAccountDetail {
  MarketAccount: string;
  MarketType: MarketTypeEnum;
  MarketAccountType: MarketAccountTypeEnum;
  Status: SubAccountReconCheckpointStatusEnum;

  Amount: string;
  Unmatched: {
    Count: number;
    Amount: string;
  };
}
