import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  type ContextType,
  type PropsWithChildren,
} from 'react';

import {
  BlotterTableStorage,
  DefaultFavoriteSecuritiesConfig,
  DefaultFavoriteSecuritiesMethods,
  DefaultWatchlistSettingsConfig,
  DefaultWatchlistSettingsMethods,
  FavoriteSecuritiesContext,
  Put,
  ROLE,
  RecentSymbolsContext,
  TabsStorage,
  WatchlistSettingsContext,
  logger,
  useConstant,
  type TabsState,
  type User,
} from '@talos/kyoko';
import type { IAnalyticsOrderDetailsTab, IAnalyticsReportTab } from 'containers/Analytics/Reports/types';
import { BLOTTER_ID_OPEN_ORDERS } from 'containers/Blotters/Orders/tokens';
import { TRADES_BLOTTER_PREFIX } from 'containers/Blotters/Trades/tokens';
import type { PortfolioViewLayoutState } from 'containers/Portfolio/PortfolioManagement/stateManagement/portfolioViewLayoutSlice';
import { get, isEqual, keys, pick, uniq } from 'lodash';
import { BlottersContext } from 'providers/BlottersContext';
import { useMethods } from 'react-use';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';
import { NUM_COLUMNS } from 'tokens/appconfig';
import { BLOTTER_ID_V1_OLD_RECENT_ORDERS, BLOTTER_ID_V1_OLD_RECENT_TRADES } from 'tokens/blotters';
import type { OrderStrategiesEnum } from 'tokens/orderStrategy';
import { isUserRole } from '../hooks/useRoleAuth';
import { enableSmartLabelledAnalyticsTabs } from './AppConfigMigrations/AnalyticsTabsMigration';
import {
  renameBalancesBlotterTabColumns,
  renamePersistedBalancesBlotterColumns,
} from './AppConfigMigrations/BalancesBlotterColumnMigrations';
import { replaceDealerMonitoringFilters } from './AppConfigMigrations/DealerMonitoringMigration';
import {
  pruneOrderDetailsBlotterState,
  prunePersistedBlotterTableState,
} from './AppConfigMigrations/PrunePersistedBlotterTableState';
import { ensureReconMismatchesBlotterTabsHaveStartDate } from './AppConfigMigrations/ReconMismatchesBlotterMigrations';
import { removeUseNewPositionsBlotter2 } from './AppConfigMigrations/RemoveUseNewPositionsBlotter2';
import type { AppConfigState } from './AppConfigProvider.types';
import { DEFAULT_APP_CONFIG } from './AppConfigProvider.types';
import { ContextGuideContext } from './ContextGuideContext';
import { DisplaySettingsContext } from './DisplaySettingsProvider';
import { MarketTabType, type GeneralMarketTab, type MarketTab } from './MarketTabs.types';
import { MarketTabsContext, useMarketTabsContext } from './MarketTabsProvider';
import { OrderPresetsContext, type IOrderPreset } from './OrderPresetsContext';
import { PortfolioSettingsContext } from './PortfolioSettingContext';
import { SoundSettingsContext } from './SoundContext';
import { TradingSettingsContext } from './TradingSettingsContext';
import {
  ANALYTICS_REPORTS_TABS_ID,
  AnalyticsTabsContext,
  useAnalyticsTabsContext,
  type UseAnalyticsTabsContextProps,
} from './useAnalyticsTabs';
import {
  MonitoringTabsContext,
  useMonitoringTabsContext,
  type MonitoringTab,
  type UseMonitoringTabsContextProps,
} from './useMonitoringTabs';

export { useBlotterState } from './BlottersContext';
export { useDisplaySettings } from './DisplaySettingsProvider';
export { useMarketTabs } from './MarketTabsProvider';
export { useSoundSettings } from './SoundContext';
export { useTradingSettings } from './TradingSettingsContext';

const DEFAULT_DATE_PICKER_EOD = { hours: '17', minutes: '00' };

interface OrderEntryContextProps {
  lastStrategyUsed: OrderStrategiesEnum;
  setLastStrategyUsed: (lastStrategy: OrderStrategiesEnum) => void;
  lastOrderFormUsed: string;
  setLastOrderFormUsed: (lastOrderFormUsed: string) => void;
}

export const OrderEntryContext = createContext({} as OrderEntryContextProps);
OrderEntryContext.displayName = 'OrderEntryContext';
export const useOrderEntry = () => useContext(OrderEntryContext);

export const fillToNumColumns = arr => {
  return [...arr, ...Array(NUM_COLUMNS - arr.length).fill([])];
};

/**
 * Given an app config, this will replace a V1 blotter (with id `oldV1BlotterID`) with a V2 version of the same
 * (mapped to the new id `newV2BlotterID`).
 *
 * @param replacedConfig App config
 * @param oldV1BlotterID ID of the V1 blotter we want to migrate from
 * @param newV2BlotterID ID of the V2 blotter we want to migrate to
 * @param defaultSort The default sorting to apply if none was found
 * @param newColIDs Mappings from `old col ID` to `new col ID`
 */
function replaceBlotterV1Config(
  replacedConfig: any,
  oldV1BlotterID: string,
  newV2BlotterID: string,
  defaultSort: string,
  newColIDs: { [key: string]: string }
) {
  if (replacedConfig == null) {
    return;
  }
  if (!('blotters2' in replacedConfig)) {
    replacedConfig.blotters2 = {};
  }

  // Find first column that contains the sorting
  const oldBlotterConfig = replacedConfig.blotters[oldV1BlotterID];
  const sort = oldBlotterConfig.sort.find(col => col.sort != null);

  // Apply it in the new config
  const newBlotterConfig: any = {
    sort: sort == null ? defaultSort : `${sort === 'desc' ? '+' : '-'}${newColIDs[sort.colId]}`,
    columns: [],
  };

  // Iterate through the old persisted blotter config and add to the new one.
  // Note that previously we only persisted _visible_ columns, so we always do `hide: false` in this loop.
  for (let i = 0; i < oldBlotterConfig.columns.length; i++) {
    const oldColID = oldBlotterConfig.columns[i];
    const width = oldBlotterConfig.widths[i];

    // Skip unwanted/no-longer-existing columns
    if (oldColID in newColIDs) {
      newBlotterConfig.columns.push({
        id: newColIDs[oldColID],
        hide: false,
        width,
      });
    }
  }

  // Apply new config and delete old one
  replacedConfig.blotters2[newV2BlotterID] = newBlotterConfig;
  delete replacedConfig.blotters[oldV1BlotterID];
}

export function replaceLegacyConfig(c: any) {
  const replacedConfig = { ...c };
  if (c.subAccountId != null) {
    delete replacedConfig.subAccountId;
    replacedConfig.defaultSubAccountId = c.subAccountId;
  }
  if (c.order?.enableGroups != null) {
    delete replacedConfig.order;
    replacedConfig.enableGroups = c.order.enableGroups;
  }
  if (c.columns) {
    delete replacedConfig.columns;
  }

  // Remove old Dealer Blotter Filter IDs
  delete replacedConfig.filteredIDCustomerUsers;
  delete replacedConfig.filteredIDCustomerBalances;
  delete replacedConfig.filteredIDCustomerTradingLimits;

  if (replacedConfig.enableSalesRFQ != null) {
    replacedConfig.enableSalesWorkflow = replacedConfig.enableSalesRFQ;
    delete replacedConfig.enableSalesRFQ;
  }

  if (typeof c.customEOD === 'number') {
    // Some old configs have the custom EOD saved as a number representing hours
    c.customEOD = {
      hours: `${c.customEOD}`,
      minutes: DEFAULT_DATE_PICKER_EOD.minutes,
    };
  }
  // Ensure that the MarketDataCard configurations have selectedMarketAccounts defined
  if (Array.isArray(replacedConfig.marketTabs)) {
    replacedConfig.marketTabs.forEach(marketTab => {
      if (marketTab && Array.isArray(marketTab.columns)) {
        marketTab.columns.forEach(col => {
          col.forEach(card => {
            card.availableMarketAccounts = uniq(card.availableMarketAccounts ?? []);
            card.selectedMarketAccounts = uniq(card.selectedMarketAccounts ?? []);
            card.availableMarkets = uniq(card.availableMarkets ?? []);
            card.selectedMarkets = uniq(card.selectedMarkets ?? []);
          });
        });
      }
    });
  }

  // Migrate v1 recent orders blotter to v2
  if (replacedConfig.blotters != null && BLOTTER_ID_V1_OLD_RECENT_ORDERS in replacedConfig.blotters) {
    replaceBlotterV1Config(replacedConfig, BLOTTER_ID_V1_OLD_RECENT_ORDERS, BLOTTER_ID_OPEN_ORDERS, '-SubmitTime', {
      submitTime: 'SubmitTime',
      side: 'Side',
      symbol: 'Symbol',
      orderQty: 'OrderQty',
      cumQty: 'CumQty',
      price: 'Price',
      filledPrice: 'filledPx',
      cumFee: 'CumFee',
      marketAccounts: 'orderMarketAccounts',
      strategy: 'Strategy',
      subAccount: 'SubAccount',
      user: 'User',
      filledPercent: 'filledPercent',
      ordStatus: 'OrdStatus',
      filledPriceNoFees: 'AvgPx',
      filledPriceWithFees: 'AvgPxAllIn',
      markets: 'Markets',
      filledNotional: 'filledNotional',
      openNotional: 'openNotional',
      totalNotional: 'totalNotional',
      orderMarkets: 'orderMarkets',
      group: 'Group',
      productType: 'productType',
      endTime: 'EndTime',
      startTime: 'StartTime',
      timestamp: 'Timestamp',
      baseQuantity: 'baseQty',
      ordType: 'OrdType',
      orderID: 'OrderID',
      clOrdID: 'ClOrdID',
      leavesQty: 'LeavesQty',
      remainQuantity: 'remainQty',
      timeInForce: 'TimeInForce',
    });
  }

  // Migrate v1 recent trades blotter to v2
  if (replacedConfig.blotters != null && BLOTTER_ID_V1_OLD_RECENT_TRADES in replacedConfig.blotters) {
    replaceBlotterV1Config(
      replacedConfig,
      BLOTTER_ID_V1_OLD_RECENT_TRADES,
      `${TRADES_BLOTTER_PREFIX}/recent-trades`,
      '-TransactTime',
      {
        transactTime: 'TransactTime',
        side: 'Side',
        symbol: 'Symbol',
        quantity: 'Quantity',
        price: 'Price',
        priceAllIn: 'PriceAllIn',
        amount: 'Amount',
        fee: 'Fee',
        user: 'User',
        tradeID: 'TradeID',
        marketAccount: 'MarketAccount',
        market: 'Market',
        subAccount: 'SubAccount',
        group: 'Group',
        orderID: 'OrderID',
        marketTradeID: 'MarketTradeID',
        timestamp: 'Timestamp',
        dealtCurrency: 'DealtCurrency',
        tradeStatus: 'TradeStatus',
        aggressorSide: 'AggressorSide',
        comments: 'Comments',
      }
    );
  }

  // Remove any temporary market tab and ensure mandatory property exist
  if (replacedConfig.marketTabs != null) {
    replacedConfig.marketTabs = replacedConfig.marketTabs
      .filter((tab: MarketTab) => !tab.isTemporary)
      .map((tab: MarketTab) => {
        if (!tab.type) {
          // Some configs may not have type saved before, we need to fix that as it's mandatory field now
          (tab as GeneralMarketTab).type = MarketTabType.Market;
        }
        return tab;
      });
  }

  ensureReconMismatchesBlotterTabsHaveStartDate(replacedConfig);
  renamePersistedBalancesBlotterColumns(replacedConfig);
  renameBalancesBlotterTabColumns(replacedConfig);
  removeUseNewPositionsBlotter2(replacedConfig);
  replaceDealerMonitoringFilters(replacedConfig);
  prunePersistedBlotterTableState(replacedConfig);
  pruneOrderDetailsBlotterState(replacedConfig);
  enableSmartLabelledAnalyticsTabs(replacedConfig);

  return replacedConfig;
}

export const initialValues = (c = {}) => {
  const appConfigInitials = replaceLegacyConfig(c);
  keys(DEFAULT_APP_CONFIG).forEach(key => {
    appConfigInitials[key] = key in appConfigInitials ? appConfigInitials[key] : DEFAULT_APP_CONFIG[key];
  });

  // Fill market tab columns with xNUM_COLUMNS Empty Arrays
  const newMarketTabs = [...(appConfigInitials?.marketTabs ?? [])];
  for (const marketTab of newMarketTabs) {
    if (
      (!marketTab.type || marketTab.type === MarketTabType.Market) &&
      (marketTab?.columns?.length ?? 0) < NUM_COLUMNS
    ) {
      marketTab.columns = fillToNumColumns(marketTab?.columns ?? []);
    }
  }
  appConfigInitials.marketTabs = newMarketTabs;

  return appConfigInitials;
};

const createMethods = (state: AppConfigState) => ({
  // Display settings
  setShowAllInPrices: showAllInPrices => ({ ...state, showAllInPrices }),
  setShowFirmLiquidity: showFirmLiquidity => ({ ...state, showFirmLiquidity }),
  setShowTotalEstimates: showTotalEstimates => ({ ...state, showTotalEstimates }),
  setHomeCurrency: homeCurrency => ({ ...state, homeCurrency }),
  setPinSidebar: pinSidebar => ({ ...state, pinSidebar }),
  setLatestReleaseNotesRead: latestReleaseNotesRead => ({ ...state, latestReleaseNotesRead }),

  // Market tabs
  setMarketTabs: marketTabs => ({ ...state, marketTabs }),

  setSortCards: sortCards => ({ ...state, sortCards }),

  // Trading
  setAllowSyntheticCcy: allowSyntheticCcy => ({ ...state, allowSyntheticCcy }),
  setAllowedSlippage: allowedSlippage => ({ ...state, allowedSlippage }),
  setDefaultSubAccountId: defaultSubAccountId => ({ ...state, defaultSubAccountId }),
  setEnableGroups: enableGroups => ({ ...state, enableGroups }),
  setConfirmOrderCancels: confirmOrderCancels => ({ ...state, confirmOrderCancels }),
  setConfirmOrderResume: confirmOrderResume => ({ ...state, confirmOrderResume }),
  setRememberSubAccountId: rememberSubAccountId => ({ ...state, rememberSubAccountId }),
  setUseTradeAllocations: useTradeAllocations => ({ ...state, useTradeAllocations }),
  setCustomEOD: customEOD => ({ ...state, customEOD }),
  setClickToTradeAll: clickToTradeAll => ({ ...state, clickToTradeAll }),
  setClickToTradeDefaultSubaccount: clickToTradeDefaultSubaccount => ({ ...state, clickToTradeDefaultSubaccount }),
  setAllowSyntheticCrosses: allowSyntheticCrosses => ({ ...state, allowSyntheticCrosses }),
  setAlwaysCheckPriceReasonability: alwaysCheckPriceReasonability => ({
    ...state,
    alwaysCheckPriceReasonability,
  }),
  setEnableCustomerTradeBookingOnCPC: enableCustomerTradeBookingOnCPC => ({
    ...state,
    enableCustomerTradeBookingOnCPC,
  }),
  setDefaultOrderStrategy: orderStrategy => ({ ...state, defaultOrderStrategy: orderStrategy }),
  setDefaultOrderFormTab: orderTab => ({ ...state, defaultOrderFormTab: orderTab }),
  setEnableOldOrderForm: enableOldOrderForm => ({ ...state, enableOldOrderForm }),
  setEnableDerivativeContractDefault: enableDerivativeContractDefault => ({
    ...state,
    enableDerivativeContractDefault,
  }),
  setExclusivelyPrimePriceOnRePriming: exclusivelyPrimePriceOnRePriming => ({
    ...state,
    exclusivelyPrimePriceOnRePriming,
  }),
  setDefaultPriceOffsets: priceOffsets => ({ ...state, defaultPriceOffsets: priceOffsets }),
  setStartTimeEntryMode: startTimeEntryMode => ({ ...state, startTimeEntryMode }),
  setEndTimeEntryMode: endTimeEntryMode => ({ ...state, endTimeEntryMode }),
  setEnableAdvancedOrderDetails: enableAdvancedOrderDetails => ({ ...state, enableAdvancedOrderDetails }),
  setAlwaysShowBestBidOffer: alwaysShowBestBidOffer => ({ ...state, alwaysShowBestBidOffer }),
  setShowOrderFormRollupSelector: showOrderFormRollupSelector => ({ ...state, showOrderFormRollupSelector }),
  setEnableNewMarketSelector: enableNewMarketSelector => ({ ...state, enableNewMarketSelector }),
  setBulkClosePositionQuoteCurrencies: bulkClosePositionQuoteCurrencies => ({
    ...state,
    bulkClosePositionQuoteCurrencies,
  }),
  setEnableReduceFirst: enableReduceFirst => ({ ...state, enableReduceFirst }),
  setEnableReduceOnly: enableReduceOnly => ({ ...state, enableReduceOnly }),

  // Trading -> Customer Pricing
  setEnableCustomerPricing: enableCustomerPricing => ({ ...state, enableCustomerPricing }),
  setEnableSalesWorkflow: enableSalesWorkflow => ({ ...state, enableSalesWorkflow }),
  setLinkCustomerHedgeSubaccount: linkCustomerHedgeSubaccount => ({ ...state, linkCustomerHedgeSubaccount }),
  setEnableCustomerBlotterColumns: enableCustomerBlotterColumns => ({ ...state, enableCustomerBlotterColumns }),
  setCustomerPricingInitialExpanded: customerPricingInitialExpanded => ({ ...state, customerPricingInitialExpanded }),
  setApplyCustomerPricingDetailsToOrderComment: applyCustomerPricingDetailsToOrderComment => ({
    ...state,
    applyCustomerPricingDetailsToOrderComment,
  }),
  setSelectedCustomerID: selectedCustomerID => ({ ...state, selectedCustomerID }),
  // Dealer
  setEnableCustomerOrderSummaryColumns: enableCustomerOrderSummaryColumns => ({
    ...state,
    enableCustomerOrderSummaryColumns,
  }),
  setFilterValueCustomerUsers: filterValueCustomerUsers => ({ ...state, filterValueCustomerUsers }),
  setFilterValueAggregations: filterValueAggregations => ({ ...state, filterValueAggregations }),
  setFilterValueSecurityMaster: filterValueSecurityMaster => ({ ...state, filterValueSecurityMaster }),
  setFilterValueCustomerAddresses: filterValueCustomerAddresses => ({ ...state, filterValueCustomerAddresses }),
  setFilterValueCustomerBalances: filterValueCustomerBalances => ({ ...state, filterValueCustomerBalances }),
  setFilterValueCustomerTradingLimits: filterValueCustomerTradingLimits => ({
    ...state,
    filterValueCustomerTradingLimits,
  }),
  setFilterValueCustomerCredit: filterValueCustomerCredit => ({
    ...state,
    filterValueCustomerCredit,
  }),
  // Principal trading limits
  setFilterValueSubAccountTradingLimits: filterValueSubAccountTradingLimits => ({
    ...state,
    filterValueSubAccountTradingLimits,
  }),
  setFilterValueSubAccountWindowLimits: filterValueSubAccountWindowLimits => ({
    ...state,
    filterValueSubAccountWindowLimits,
  }),

  // Blotters
  setGroupMarketAccounts: groupMarketAccounts => ({ ...state, groupMarketAccounts }),
  setBalancesHiddenSymbols: balancesHiddenSymbols => ({ ...state, balancesHiddenSymbols }),
  setBalancesHiddenMarketAccountIDs: balancesHiddenMarketAccountIDs => ({ ...state, balancesHiddenMarketAccountIDs }),
  setBalancesMarketAccountIDsOrder: balancesMarketAccountIDsOrder => ({ ...state, balancesMarketAccountIDsOrder }),
  setBalancesCurrenciesOrder: balancesCurrenciesOrder => ({ ...state, balancesCurrenciesOrder }),
  setBlotterState: (blotterID, blotterState) => ({
    ...state,
    blotters: { ...state.blotters, [blotterID]: blotterState },
  }),
  clearBlotterState: (blotterID?: string) => {
    const nextState = {
      ...state,
      blotters: { ...state.blotters },
      blotters2: { ...state.blotters2 },
    } as AppConfigState;
    if (blotterID == null) {
      delete nextState.blotters;
      nextState.blotters2 = {};
    } else {
      delete nextState.blotters?.[blotterID];
      delete nextState.blotters2?.[blotterID];
    }
    return nextState;
  },
  setIsExpanded: (isExpanded: boolean) => ({ ...state, isExpanded }),
  setIsMinimized: (isMinimized: boolean) => ({ ...state, isMinimized }),

  setSessionBlotterHeight: (sessionBlotterHeight: number) => ({ ...state, sessionBlotterHeight }),
  setCustomerBalancesShowZeroBalances: (customerBalancesShowZeroBalances: boolean) => ({
    ...state,
    customerBalancesShowZeroBalances,
  }),

  setLastOrderFormUsed: lastOrderFormUsed => ({ ...state, lastOrderFormUsed }),

  // Symbol selector
  setRecentSymbols: recentSymbols => ({ ...state, recentSymbols }),

  // Order entry
  setLastStrategyUsed: lastStrategyUsed => ({ ...state, lastStrategyUsed }),

  // Sounds
  setEnableSoundEffects: enableSoundEffects => ({ ...state, enableSoundEffects }),
  setEnabledSounds: enabledSounds => ({ ...state, enabledSounds }),

  // V2-blotters storage
  setBlotterColumnState: (blotterID, columns) => ({
    ...state,
    blotters2: { ...state.blotters2, [blotterID]: { ...state.blotters2[blotterID], columns } },
  }),
  setBlotterSortState: (blotterID, sort) => ({
    ...state,
    blotters2: { ...state.blotters2, [blotterID]: { ...state.blotters2[blotterID], sort } },
  }),
  setBlotterFilterState: (blotterID, filter) => ({
    ...state,
    blotters2: { ...state.blotters2, [blotterID]: { ...state.blotters2[blotterID], filter } },
  }),
  setBlotterRowGroupsOpenedState: (blotterID, rowGroupsOpened) => ({
    ...state,
    blotters2: { ...state.blotters2, [blotterID]: { ...state.blotters2[blotterID], rowGroupsOpened } },
  }),

  // Tabs
  setTabsItems: (tabsID, items) => ({
    ...state,
    tabs: { ...state.tabs, [tabsID]: { ...state.tabs[tabsID], items } },
  }),
  setTabsSelectedIndex: (tabsID, selectedIndex) => ({
    ...state,
    tabs: { ...state.tabs, [tabsID]: { ...state.tabs[tabsID], selectedIndex } },
  }),

  // Watchlist
  setIsVisible: isVisible => ({
    ...state,
    isVisible,
  }),
  setShowOnlyFavorites: showOnlyFavorites => ({
    ...state,
    showOnlyFavorites,
  }),
  setSecurityTypes: securityTypes => ({
    ...state,
    securityTypes,
  }),
  setFilteredCurrencies: filteredCurrencies => ({
    ...state,
    filteredCurrencies,
  }),

  // Favorite securites
  setFavoriteSecurities: favoriteSecurities => ({
    ...state,
    favoriteSecurities,
  }),

  // Order presets
  setOrderPresets: (orderPresets: IOrderPreset[]) => ({
    ...state,
    orderPresetsList: orderPresets,
  }),

  deleteOrderPreset: (id: string) => {
    const orderPresetsList = state.orderPresetsList.filter(preset => preset.id !== id);
    return { ...state, orderPresetsList };
  },

  // Portfolio Management settings
  setEnablePMS: (enablePMS: boolean) => ({ ...state, enablePMS }),
  setTreatStablecoinsAsCash: (treatStablecoinsAsCash: boolean) => ({
    ...state,
    treatStablecoinsAsCash,
  }),
  setPortfolioViewState: (portfolioViewState: PortfolioViewLayoutState['viewState']) => ({
    ...state,
    portfolioViewState,
  }),

  // Context Guide
  setGuide: (guideID, value) => {
    return {
      ...state,
      contextGuide: { ...state.contextGuide, [guideID]: value },
    };
  },
});

export const AppConfigProvider = function AppConfigProvider({ user, children }: PropsWithChildren<{ user: User }>) {
  // useConstant to circumvent bug https://github.com/streamich/react-use/issues/1286
  const initialState = useConstant(initialValues(JSON.parse(user.AppConfig || '{}')));
  const [state, methods]: [AppConfigState, ReturnType<typeof createMethods>] = useMethods<
    typeof createMethods,
    AppConfigState
  >(createMethods as any, initialState) as [AppConfigState, ReturnType<typeof createMethods>];
  const appConfigSubject = useConstant(new BehaviorSubject(initialState));

  useEffect(() => {
    if (state && !isEqual(appConfigSubject.value, state)) {
      appConfigSubject.next(state);
    }
  }, [appConfigSubject, state]);

  useEffect(() => {
    // Skip the first entry as we already initialize the BehaviorSubject with initialState
    const sub = appConfigSubject.pipe(skip(1), debounceTime(300)).subscribe(appConfig => {
      Put(import.meta.env.VITE_AVA_API_ENDPOINT, `/user/app_config`, {
        appConfig: JSON.stringify(appConfig),
      }).catch((e: ErrorEvent) => {
        logger.error(new Error(`Failed to update user app_config`), {
          extra: {
            errorMessage: e.message,
            appConfigLength: JSON.stringify(appConfig).length,
          },
        });
        console.error(e);
      });
    });
    return () => {
      sub.unsubscribe();
    };
  }, [appConfigSubject]);

  const enableAdvancedOrderDetails = useMemo((): boolean => {
    // Initial state depends on user roles
    // https://talostrading.atlassian.net/browse/UI-3969
    if (state?.enableAdvancedOrderDetails === undefined) {
      return [ROLE.TALOS_ADMIN, ROLE.ADMIN, ROLE.TALOS_SUPPORT, ROLE.TALOS_VIEWER].some(role => isUserRole(user, role));
    } else {
      return state.enableAdvancedOrderDetails;
    }
  }, [user, state]);

  const {
    marketTabs: _marketTabs,
    saveOrDiscardTemporaryTab,
    prepareDeepDiveMarketTab,
    updateDeepDiveMarketTabData,
    updateDeepDiveMarketTabSymbol,
    updateOrderDetailsMarketTab,
    removeActiveTab,
    prepareReconDetailsMarketTab,
    prepareAccountLedgerEventsDetailsMarketTab,
  } = useMarketTabsContext({
    initialMarketTabs: initialState.marketTabs,
    setMarketTabs: methods.setMarketTabs,
  });

  const marketTabs = useMemo<ContextType<typeof MarketTabsContext>>(
    () => ({
      ..._marketTabs,
      sortCards: state.sortCards,
      setSortCards: methods.setSortCards,
      saveOrDiscardTemporaryTab,
      prepareDeepDiveMarketTab,
      prepareReconDetailsMarketTab,
      prepareAccountLedgerEventsDetailsMarketTab,
      updateDeepDiveMarketTabData,
      updateDeepDiveMarketTabSymbol,
      updateOrderDetailsMarketTab,
      removeActiveTab,
    }),
    [
      _marketTabs,
      state.sortCards,
      saveOrDiscardTemporaryTab,
      methods.setSortCards,
      prepareDeepDiveMarketTab,
      prepareReconDetailsMarketTab,
      prepareAccountLedgerEventsDetailsMarketTab,
      updateDeepDiveMarketTabData,
      updateDeepDiveMarketTabSymbol,
      updateOrderDetailsMarketTab,
      removeActiveTab,
    ]
  );

  const recentSymbols = useMemo<ContextType<typeof RecentSymbolsContext>>(
    () => ({
      recentSymbols: state.recentSymbols,
      setRecentSymbols: methods.setRecentSymbols,
    }),
    [state.recentSymbols, methods.setRecentSymbols]
  );

  const display = useMemo<ContextType<typeof DisplaySettingsContext>>(
    () => ({
      showAllInPrices: state.showAllInPrices,
      showFirmLiquidity: state.showFirmLiquidity,
      showTotalEstimates: state.showTotalEstimates,
      homeCurrency: state.homeCurrency,
      customEOD: state.customEOD,
      pinSidebar: state.pinSidebar,
      latestReleaseNotesRead: state.latestReleaseNotesRead,
      setShowAllInPrices: methods.setShowAllInPrices,
      setShowFirmLiquidity: methods.setShowFirmLiquidity,
      setShowTotalEstimates: methods.setShowTotalEstimates,
      setHomeCurrency: methods.setHomeCurrency,
      setCustomEOD: methods.setCustomEOD,
      setPinSidebar: methods.setPinSidebar,
      setLatestReleaseNotesRead: methods.setLatestReleaseNotesRead,
    }),
    [
      state.showAllInPrices,
      state.showFirmLiquidity,
      state.showTotalEstimates,
      state.homeCurrency,
      state.customEOD,
      state.pinSidebar,
      state.latestReleaseNotesRead,
      methods.setShowAllInPrices,
      methods.setShowFirmLiquidity,
      methods.setShowTotalEstimates,
      methods.setHomeCurrency,
      methods.setCustomEOD,
      methods.setPinSidebar,
      methods.setLatestReleaseNotesRead,
    ]
  );

  const trading = useMemo<ContextType<typeof TradingSettingsContext>>(
    () => ({
      allowSyntheticCcy: state.allowSyntheticCcy,
      setAllowSyntheticCcy: methods.setAllowSyntheticCcy,
      allowedSlippage: state.allowedSlippage,
      setAllowedSlippage: methods.setAllowedSlippage,
      defaultSubAccountId: state.defaultSubAccountId,
      setDefaultSubAccountId: methods.setDefaultSubAccountId,
      enableGroups: state.enableGroups,
      confirmOrderCancels: state.confirmOrderCancels,
      confirmOrderResume: state.confirmOrderResume,
      allowSyntheticCrosses: state.allowSyntheticCrosses,
      setEnableGroups: methods.setEnableGroups,
      rememberSubAccountId: state.rememberSubAccountId,
      setRememberSubAccountId: methods.setRememberSubAccountId,
      useTradeAllocations: state.useTradeAllocations,
      setUseTradeAllocations: methods.setUseTradeAllocations,
      clickToTradeAll: state.clickToTradeAll,
      setClickToTradeAll: methods.setClickToTradeAll,
      clickToTradeDefaultSubaccount: state.clickToTradeDefaultSubaccount,
      setClickToTradeDefaultSubaccount: methods.setClickToTradeDefaultSubaccount,
      setAllowSyntheticCrosses: methods.setAllowSyntheticCrosses,
      setConfirmOrderCancels: methods.setConfirmOrderCancels,
      setConfirmOrderResume: methods.setConfirmOrderResume,
      enableCustomerPricing: state.enableCustomerPricing,
      setEnableCustomerPricing: methods.setEnableCustomerPricing,
      enableSalesWorkflow: state.enableSalesWorkflow,
      setEnableSalesWorkflow: methods.setEnableSalesWorkflow,
      linkCustomerHedgeSubaccount: state.linkCustomerHedgeSubaccount,
      setLinkCustomerHedgeSubaccount: methods.setLinkCustomerHedgeSubaccount,
      enableDerivativeContractDefault: state.enableDerivativeContractDefault,
      exclusivelyPrimePriceOnRePriming: state.exclusivelyPrimePriceOnRePriming,
      setEnableOldOrderForm: methods.setEnableOldOrderForm,
      setEnableDerivativeContractDefault: methods.setEnableDerivativeContractDefault,
      setExclusivelyPrimePriceOnRePriming: methods.setExclusivelyPrimePriceOnRePriming,
      enableCustomerBlotterColumns: state.enableCustomerBlotterColumns,
      setEnableCustomerBlotterColumns: methods.setEnableCustomerBlotterColumns,
      customerPricingInitialExpanded: state.customerPricingInitialExpanded,
      setCustomerPricingInitialExpanded: methods.setCustomerPricingInitialExpanded,
      applyCustomerPricingDetailsToOrderComment: state.applyCustomerPricingDetailsToOrderComment,
      setApplyCustomerPricingDetailsToOrderComment: methods.setApplyCustomerPricingDetailsToOrderComment,
      selectedCustomerID: state.selectedCustomerID,
      setSelectedCustomerID: methods.setSelectedCustomerID,
      alwaysCheckPriceReasonability: state.alwaysCheckPriceReasonability,
      setAlwaysCheckPriceReasonability: methods.setAlwaysCheckPriceReasonability,
      enableCustomerTradeBookingOnCPC: state.enableCustomerTradeBookingOnCPC,
      setEnableCustomerTradeBookingOnCPC: methods.setEnableCustomerTradeBookingOnCPC,
      defaultOrderStrategy: state.defaultOrderStrategy,
      setDefaultOrderStrategy: methods.setDefaultOrderStrategy,
      defaultOrderFormTab: state.defaultOrderFormTab,
      setDefaultOrderFormTab: methods.setDefaultOrderFormTab,
      defaultPriceOffsets: state.defaultPriceOffsets,
      setDefaultPriceOffsets: methods.setDefaultPriceOffsets,
      startTimeEntryMode: state.startTimeEntryMode,
      setStartTimeEntryMode: methods.setStartTimeEntryMode,
      endTimeEntryMode: state.endTimeEntryMode,
      setEndTimeEntryMode: methods.setEndTimeEntryMode,
      enableAdvancedOrderDetails,
      setEnableAdvancedOrderDetails: methods.setEnableAdvancedOrderDetails,
      alwaysShowBestBidOffer: state.alwaysShowBestBidOffer,
      setAlwaysShowBestBidOffer: methods.setAlwaysShowBestBidOffer,
      showOrderFormRollupSelector: state.showOrderFormRollupSelector,
      setShowOrderFormRollupSelector: methods.setShowOrderFormRollupSelector,
      enableNewMarketSelector: state.enableNewMarketSelector,
      setEnableNewMarketSelector: methods.setEnableNewMarketSelector,
      bulkClosePositionQuoteCurrencies: state.bulkClosePositionQuoteCurrencies,
      setBulkClosePositionQuoteCurrencies: methods.setBulkClosePositionQuoteCurrencies,
      enableReduceFirst: state.enableReduceFirst,
      setEnableReduceFirst: methods.setEnableReduceFirst,
      enableReduceOnly: state.enableReduceOnly,
      setEnableReduceOnly: methods.setEnableReduceOnly,
    }),
    [
      state.allowSyntheticCcy,
      state.allowedSlippage,
      state.defaultSubAccountId,
      state.enableGroups,
      state.confirmOrderCancels,
      state.confirmOrderResume,
      state.allowSyntheticCrosses,
      state.rememberSubAccountId,
      state.useTradeAllocations,
      state.clickToTradeAll,
      state.clickToTradeDefaultSubaccount,
      state.enableCustomerPricing,
      state.enableSalesWorkflow,
      state.linkCustomerHedgeSubaccount,
      state.enableDerivativeContractDefault,
      state.exclusivelyPrimePriceOnRePriming,
      state.enableCustomerBlotterColumns,
      state.customerPricingInitialExpanded,
      state.applyCustomerPricingDetailsToOrderComment,
      state.selectedCustomerID,
      state.alwaysCheckPriceReasonability,
      state.enableCustomerTradeBookingOnCPC,
      state.defaultOrderStrategy,
      state.defaultOrderFormTab,
      state.defaultPriceOffsets,
      state.startTimeEntryMode,
      state.endTimeEntryMode,
      enableAdvancedOrderDetails,
      state.alwaysShowBestBidOffer,
      state.showOrderFormRollupSelector,
      state.enableNewMarketSelector,
      state.bulkClosePositionQuoteCurrencies,
      state.enableReduceFirst,
      state.enableReduceOnly,
      methods.setAllowSyntheticCcy,
      methods.setAllowedSlippage,
      methods.setDefaultSubAccountId,
      methods.setEnableGroups,
      methods.setRememberSubAccountId,
      methods.setUseTradeAllocations,
      methods.setClickToTradeAll,
      methods.setClickToTradeDefaultSubaccount,
      methods.setAllowSyntheticCrosses,
      methods.setConfirmOrderCancels,
      methods.setConfirmOrderResume,
      methods.setEnableCustomerPricing,
      methods.setLinkCustomerHedgeSubaccount,
      methods.setEnableSalesWorkflow,
      methods.setEnableOldOrderForm,
      methods.setEnableDerivativeContractDefault,
      methods.setExclusivelyPrimePriceOnRePriming,
      methods.setEnableCustomerBlotterColumns,
      methods.setCustomerPricingInitialExpanded,
      methods.setApplyCustomerPricingDetailsToOrderComment,
      methods.setSelectedCustomerID,
      methods.setAlwaysCheckPriceReasonability,
      methods.setEnableCustomerTradeBookingOnCPC,
      methods.setDefaultOrderStrategy,
      methods.setDefaultOrderFormTab,
      methods.setDefaultPriceOffsets,
      methods.setStartTimeEntryMode,
      methods.setEndTimeEntryMode,
      methods.setEnableAdvancedOrderDetails,
      methods.setAlwaysShowBestBidOffer,
      methods.setShowOrderFormRollupSelector,
      methods.setEnableNewMarketSelector,
      methods.setBulkClosePositionQuoteCurrencies,
      methods.setEnableReduceFirst,
      methods.setEnableReduceOnly,
    ]
  );

  const blotters = useMemo<ContextType<typeof BlottersContext>>(
    () => ({
      blotters: state.blotters,
      setBlotterState: methods.setBlotterState,
      clearBlotterState: methods.clearBlotterState,
      groupMarketAccounts: state.groupMarketAccounts,
      setGroupMarketAccounts: methods.setGroupMarketAccounts,
      balancesHiddenSymbols: state.balancesHiddenSymbols,
      setBalancesHiddenSymbols: methods.setBalancesHiddenSymbols,
      balancesHiddenMarketAccountIDs: state.balancesHiddenMarketAccountIDs,
      setBalancesHiddenMarketAccountIDs: methods.setBalancesHiddenMarketAccountIDs,
      balancesMarketAccountIDsOrder: state.balancesMarketAccountIDsOrder,
      setBalancesMarketAccountIDsOrder: methods.setBalancesMarketAccountIDsOrder,
      balancesCurrenciesOrder: state.balancesCurrenciesOrder,
      setBalancesCurrenciesOrder: methods.setBalancesCurrenciesOrder,
      filterValueCustomerUsers: state.filterValueCustomerUsers,
      enableCustomerOrderSummaryColumns: state.enableCustomerOrderSummaryColumns,
      setEnableCustomerOrderSummaryColumns: methods.setEnableCustomerOrderSummaryColumns,
      setFilterValueCustomerUsers: methods.setFilterValueCustomerUsers,
      filterValueAggregations: state.filterValueAggregations,
      setFilterValueAggregations: methods.setFilterValueAggregations,
      filterValueSecurityMaster: state.filterValueSecurityMaster,
      setFilterValueSecurityMaster: methods.setFilterValueSecurityMaster,
      filterValueCustomerAddresses: state.filterValueCustomerAddresses,
      setFilterValueCustomerAddresses: methods.setFilterValueCustomerAddresses,
      filterValueCustomerBalances: state.filterValueCustomerBalances,
      setFilterValueCustomerBalances: methods.setFilterValueCustomerBalances,
      filterValueCustomerTradingLimits: state.filterValueCustomerTradingLimits,
      setFilterValueCustomerTradingLimits: methods.setFilterValueCustomerTradingLimits,
      filterValueCustomerCredit: state.filterValueCustomerCredit,
      setFilterValueCustomerCredit: methods.setFilterValueCustomerCredit,
      filterValueSubAccountTradingLimits: state.filterValueSubAccountTradingLimits,
      setFilterValueSubAccountTradingLimits: methods.setFilterValueSubAccountTradingLimits,
      filterValueSubAccountWindowLimits: state.filterValueSubAccountWindowLimits,
      setFilterValueSubAccountWindowLimits: methods.setFilterValueSubAccountWindowLimits,
      isExpanded: state.isExpanded,
      setIsExpanded: methods.setIsExpanded,
      isMinimized: state.isMinimized,
      setIsMinimized: methods.setIsMinimized,
      sessionBlotterHeight: state.sessionBlotterHeight,
      setSessionBlotterHeight: methods.setSessionBlotterHeight,
      customerBalancesShowZeroBalances: state.customerBalancesShowZeroBalances,
      setCustomerBalancesShowZeroBalances: methods.setCustomerBalancesShowZeroBalances,
    }),
    [
      state.blotters,
      state.groupMarketAccounts,
      state.balancesHiddenSymbols,
      state.balancesHiddenMarketAccountIDs,
      state.balancesMarketAccountIDsOrder,
      state.balancesCurrenciesOrder,
      state.filterValueCustomerUsers,
      state.enableCustomerOrderSummaryColumns,
      state.filterValueAggregations,
      state.filterValueSecurityMaster,
      state.filterValueCustomerAddresses,
      state.filterValueCustomerBalances,
      state.filterValueCustomerTradingLimits,
      state.filterValueCustomerCredit,
      state.filterValueSubAccountTradingLimits,
      state.filterValueSubAccountWindowLimits,
      state.isExpanded,
      state.isMinimized,
      state.sessionBlotterHeight,
      state.customerBalancesShowZeroBalances,
      methods.setBlotterState,
      methods.clearBlotterState,
      methods.setGroupMarketAccounts,
      methods.setBalancesHiddenSymbols,
      methods.setBalancesHiddenMarketAccountIDs,
      methods.setBalancesMarketAccountIDsOrder,
      methods.setBalancesCurrenciesOrder,
      methods.setEnableCustomerOrderSummaryColumns,
      methods.setFilterValueCustomerUsers,
      methods.setFilterValueAggregations,
      methods.setFilterValueSecurityMaster,
      methods.setFilterValueCustomerAddresses,
      methods.setFilterValueCustomerBalances,
      methods.setFilterValueCustomerTradingLimits,
      methods.setFilterValueCustomerCredit,
      methods.setFilterValueSubAccountTradingLimits,
      methods.setFilterValueSubAccountWindowLimits,
      methods.setIsExpanded,
      methods.setIsMinimized,
      methods.setSessionBlotterHeight,
      methods.setCustomerBalancesShowZeroBalances,
    ]
  );

  const orderEntry = useMemo<ContextType<typeof OrderEntryContext>>(
    () => ({
      lastStrategyUsed: state.lastStrategyUsed,
      setLastStrategyUsed: methods.setLastStrategyUsed,
      lastOrderFormUsed: state.lastOrderFormUsed,
      setLastOrderFormUsed: methods.setLastOrderFormUsed,
    }),
    [state.lastStrategyUsed, methods.setLastStrategyUsed, state.lastOrderFormUsed, methods.setLastOrderFormUsed]
  );

  const sounds = useMemo<ContextType<typeof SoundSettingsContext>>(
    () => ({
      enableSoundEffects: state.enableSoundEffects,
      enabledSounds: state.enabledSounds,
      setEnableSoundEffects: methods.setEnableSoundEffects,
      setEnabledSounds: methods.setEnabledSounds,
    }),
    [state.enableSoundEffects, state.enabledSounds, methods.setEnableSoundEffects, methods.setEnabledSounds]
  );

  const watchlist = useMemo<ContextType<typeof WatchlistSettingsContext>>(
    () => ({
      ...(pick(state, keys(DefaultWatchlistSettingsConfig)) as typeof DefaultWatchlistSettingsConfig),
      ...(pick(methods, keys(DefaultWatchlistSettingsMethods)) as typeof DefaultWatchlistSettingsMethods),
    }),
    [methods, state]
  );

  const favoriteSecurities = useMemo<ContextType<typeof FavoriteSecuritiesContext>>(
    () => ({
      ...(pick(state, keys(DefaultFavoriteSecuritiesConfig)) as typeof DefaultFavoriteSecuritiesConfig),
      ...(pick(methods, keys(DefaultFavoriteSecuritiesMethods)) as typeof DefaultFavoriteSecuritiesMethods),
    }),
    [methods, state]
  );

  // Not a big fan of this
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const getBlotterState = useCallback(key => stateRef.current.blotters2[key], []);
  const blotterStorage = useMemo<ContextType<typeof BlotterTableStorage>>(
    () => ({
      getState: getBlotterState,
      setColumnState: methods.setBlotterColumnState,
      setSortState: methods.setBlotterSortState,
      setFilterState: methods.setBlotterFilterState,
      setRowGroupsOpenedState: methods.setBlotterRowGroupsOpenedState,
    }),
    [
      getBlotterState,
      methods.setBlotterColumnState,
      methods.setBlotterSortState,
      methods.setBlotterFilterState,
      methods.setBlotterRowGroupsOpenedState,
    ]
  );

  const getTabsState = useCallback(key => state.tabs[key], [state.tabs]);
  const tabsStorage = useMemo<ContextType<typeof TabsStorage>>(
    () => ({
      getState: getTabsState,
      setSelectedIndex: methods.setTabsSelectedIndex,
      setItems: methods.setTabsItems,
    }),
    [getTabsState, methods.setTabsItems, methods.setTabsSelectedIndex]
  );

  const monitoringTabsProps = useMemo<UseMonitoringTabsContextProps>(() => {
    const tabId = 'monitoring-blotters';
    const saveState = getTabsState(tabId) as TabsState<MonitoringTab>;
    return {
      initialTabs: saveState?.items ?? [],
      initialSelectedIndex: saveState?.selectedIndex ?? 0,
      saveTabs: newItems => {
        methods.setTabsItems(tabId, newItems);
      },
    };
  }, [getTabsState, methods]);
  const monitoringTabs = useMonitoringTabsContext(monitoringTabsProps);

  const analyticsTabsProps = useMemo(() => {
    const saveState = getTabsState(ANALYTICS_REPORTS_TABS_ID) as TabsState<
      IAnalyticsReportTab | IAnalyticsOrderDetailsTab
    >;
    return {
      initialTabs: saveState?.items ?? [],
      initialSelectedIndex: saveState?.selectedIndex ?? 0,
      saveTabs: newItems => {
        methods.setTabsItems(ANALYTICS_REPORTS_TABS_ID, newItems);
      },
    } satisfies UseAnalyticsTabsContextProps;
  }, [getTabsState, methods]);
  const analyticsTabs = useAnalyticsTabsContext(analyticsTabsProps);

  const orderPresets = useMemo<ContextType<typeof OrderPresetsContext>>(
    () => ({
      orderPresetsList: state.orderPresetsList,
      orderPresetsByID: new Map(state.orderPresetsList.map(p => [p.id, p])),
      createOrderPreset: (preset: IOrderPreset) => {
        methods.setOrderPresets([...state.orderPresetsList, preset].sort((a, b) => a.name.localeCompare(b.name)));
      },
      updateOrderPreset: (id: string, preset: Omit<IOrderPreset, 'id'>) => {
        methods.setOrderPresets(state.orderPresetsList.map(p => (p.id === id ? { id: p.id, ...preset } : p)));
      },
      deleteOrderPreset: (id: string) => {
        methods.deleteOrderPreset(id);
      },
    }),
    [state.orderPresetsList, methods]
  );

  const portfolioSettings = useMemo<ContextType<typeof PortfolioSettingsContext>>(
    () => ({
      enablePMS: state.enablePMS,
      treatStablecoinsAsCash: state.treatStablecoinsAsCash,
      portfolioViewState: state.portfolioViewState,
      setEnablePMS: methods.setEnablePMS,
      setTreatStablecoinsAsCash: methods.setTreatStablecoinsAsCash,
      setPortfolioViewState: methods.setPortfolioViewState,
    }),
    [
      state.enablePMS,
      state.treatStablecoinsAsCash,
      state.portfolioViewState,
      methods.setEnablePMS,
      methods.setTreatStablecoinsAsCash,
      methods.setPortfolioViewState,
    ]
  );

  const contextGuide = useMemo<ContextType<typeof ContextGuideContext>>(
    () => ({
      contextGuide: state.contextGuide,
      setGuide: methods.setGuide,
      getGuide: (guideID: string) => get(state.contextGuide, guideID, false),
    }),
    [state, methods]
  );

  if (
    recentSymbols == null ||
    display == null ||
    marketTabs == null ||
    trading == null ||
    blotters == null ||
    orderEntry == null ||
    blotterStorage == null ||
    tabsStorage == null ||
    orderPresets == null ||
    portfolioSettings == null
  ) {
    return null;
  }

  return (
    <BlotterTableStorage.Provider value={blotterStorage}>
      <OrderEntryContext.Provider value={orderEntry}>
        <RecentSymbolsContext.Provider value={recentSymbols}>
          <DisplaySettingsContext.Provider value={display}>
            <MarketTabsContext.Provider value={marketTabs}>
              <TradingSettingsContext.Provider value={trading}>
                <SoundSettingsContext.Provider value={sounds}>
                  <BlottersContext.Provider value={blotters}>
                    <TabsStorage.Provider value={tabsStorage}>
                      <WatchlistSettingsContext.Provider value={watchlist}>
                        <FavoriteSecuritiesContext.Provider value={favoriteSecurities}>
                          <MonitoringTabsContext.Provider value={monitoringTabs}>
                            <AnalyticsTabsContext.Provider value={analyticsTabs}>
                              <OrderPresetsContext.Provider value={orderPresets}>
                                <PortfolioSettingsContext.Provider value={portfolioSettings}>
                                  <ContextGuideContext.Provider value={contextGuide}>
                                    {children}
                                  </ContextGuideContext.Provider>
                                </PortfolioSettingsContext.Provider>
                              </OrderPresetsContext.Provider>
                            </AnalyticsTabsContext.Provider>
                          </MonitoringTabsContext.Provider>
                        </FavoriteSecuritiesContext.Provider>
                      </WatchlistSettingsContext.Provider>
                    </TabsStorage.Provider>
                  </BlottersContext.Provider>
                </SoundSettingsContext.Provider>
              </TradingSettingsContext.Provider>
            </MarketTabsContext.Provider>
          </DisplaySettingsContext.Provider>
        </RecentSymbolsContext.Provider>
      </OrderEntryContext.Provider>
    </BlotterTableStorage.Provider>
  );
};
