import { uniq } from 'lodash';
import { toBigWithDefault } from '../utils';
import type { LedgerUpdateTypeEnum } from './types';

// To be replaced by backend enum from types.ts once backend is merged
export enum SubAccountReconMatchStatusEnum {
  Unmatched = 'Unmatched',
  UnmatchedSubAccount = 'UnmatchedSubAccount',
  UnmatchedMarketAccount = 'UnmatchedMarketAccount',
  Matched = 'Matched',
  Resolved = 'Resolved',
  Discarded = 'Discarded',
}

export class SubAccountReconMatch {
  ID!: string;
  Asset!: string;
  CheckpointID!: string;
  LastUpdateTime!: string;
  EventType!: LedgerUpdateTypeEnum;
  Status!: SubAccountReconMatchStatusEnum;
  TransactTime!: string;
  TradeID!: string;

  Comments?: string;

  /** An array of the sub accounts (name) represented in this ReconMatch */
  subAccounts: string[];

  get breakAmount() {
    const marketAccountEventAmount = toBigWithDefault(this.MarketAccountEvent?.Amount, 0);
    const subAccountEventsAmount = toBigWithDefault(this.SubAccountEvents?.Amount, 0);
    return subAccountEventsAmount.minus(marketAccountEventAmount).toFixed();
  }

  get hasBreak() {
    return !toBigWithDefault(this.breakAmount, 0).eq(0);
  }

  MarketAccountEvent?: {
    Amount: string;
    AvgCost: string;
    AvgCostCurrency: string;
    Market: string;
    MarketAccount: string;
    Comments?: string;
  };

  SubAccountEvents?: {
    Amount: string;
    Events?: {
      Amount: string;
      AvgCost: string;
      AvgCostCurrency: string;
      SubAccount: string;
      Comments?: string;
    }[];
  };

  constructor(data: SubAccountReconMatch) {
    Object.assign(this, data);
    this.subAccounts = uniq((this.SubAccountEvents?.Events ?? []).map(event => event.SubAccount));
  }
}
