import {
  FormRowStatus,
  InstrumentCompositionEnum,
  useDynamicCallback,
  useUnifiedLiquidityContext,
  type AgGridSearchSelectDropdownProps,
  type Column,
  type CustomerSecurity,
  type Security,
  type ToggleParams,
} from '@talos/kyoko';
import type { ICellEditorParams } from 'ag-grid-community';
import { useFeatureFlag } from 'hooks';
import { useMemo } from 'react';

interface SecuritySelectItem {
  value: Security | undefined;
  label: string;
  description: string;
}

export const useSymbolColumn = (securitiesList: Security[], filteredSecuritiesList: Security[]): Column => {
  const cellEditorParams = useDynamicCallback((params: ICellEditorParams) => {
    const items = filteredSecuritiesList.map(s => ({
      value: s,
      label: s.DisplaySymbol,
      description: s.Description,
    }));
    return {
      ...params,
      useSearchSelectParams: {
        items,
        getLabel: s => s.label,
        getDescription: s => s.description ?? '',
      },
      searchPlaceholder: 'Security',
    } satisfies AgGridSearchSelectDropdownProps<SecuritySelectItem>;
  });
  const valueGetter = useDynamicCallback(({ data }: { data: CustomerSecurity }) => {
    return securitiesList.find(s => s.Symbol === data.Symbol)?.DisplaySymbol ?? data.Symbol;
  });

  return useMemo(
    () => ({
      type: 'custom',
      field: 'Symbol',
      editable: ({ node }) => node.data.formRow.status === FormRowStatus.Added,
      params: {
        headerName: 'Symbol',
        width: 140,
        suppressKeyboardEvent: () => true,
        cellEditor: 'searchSelectDropdown',
        cellEditorPopup: true,
        cellEditorParams,
        valueSetter: ({ newValue, data }) => {
          if (newValue == null) {
            return;
          }
          data.Symbol = newValue.value.Symbol;
        },
        valueGetter,
      },
    }),
    [cellEditorParams, valueGetter]
  );
};

const symbolCurrenciesRegex = /^(.+)-(.+)$/;
const getUnderlyingSecuritiesForSymbol = (symbol: string, securitiesList: Security[]): Security[] => {
  const [, baseCurrency, quoteCurrency] = symbolCurrenciesRegex.exec(symbol) ?? [];
  return securitiesList.filter(
    sec =>
      sec.Composition === InstrumentCompositionEnum.Synthetic &&
      sec.BaseCurrency === baseCurrency &&
      sec.QuoteCurrency === quoteCurrency
  );
};

export const useUnderlyingCrossSymbolColumn = (
  securitiesList: Security[],
  filteredSecuritiesList: Security[]
): Column => {
  const cellEditorParams = useDynamicCallback((params: ICellEditorParams<Security>) => {
    const { data } = params;
    const securities = getUnderlyingSecuritiesForSymbol(data.Symbol, securitiesList);
    const items: SecuritySelectItem[] = securities.map(sec => ({
      value: sec,
      label: sec.Symbol,
      description: sec.DisplaySymbol,
    }));
    items.unshift({
      label: '*',
      value: undefined,
      description: 'All symbols',
    });
    return {
      ...params,
      useSearchSelectParams: {
        items,
        getLabel: sec => sec.label,
        getDescription: sec => sec.description ?? '',
      },
      searchPlaceholder: 'Underlying Cross Symbol',
    } satisfies AgGridSearchSelectDropdownProps<SecuritySelectItem>;
  });
  const valueGetter = useDynamicCallback(({ data }: { data: CustomerSecurity & { DisplaySymbol?: string } }) => {
    const securities = getUnderlyingSecuritiesForSymbol(data.Symbol, securitiesList);
    if (securities.length > 0) {
      return data.UnderlyingCrossSymbol ?? data.DisplaySymbol ?? '*';
    }

    return '*';
  });
  return useMemo(
    () => ({
      type: 'custom',
      field: 'UnderlyingCrossSymbol',
      editable: true,
      description: 'Synthetic symbol used if pricing via synthetic cross',
      width: 200,
      hide: true,
      params: {
        suppressKeyboardEvent: () => true,
        cellEditor: 'searchSelectDropdown',
        cellEditorPopup: true,
        cellEditorParams,
        valueSetter: ({ newValue, data }) => {
          delete data.UnderlyingCrossSymbol;

          if (newValue == null) {
            return false;
          }

          if (newValue.value === undefined) {
            data.PriceViaSyntheticCross = 'Disabled';
          } else {
            data.UnderlyingCrossSymbol = newValue.value?.Symbol;
          }

          return true;
        },
        valueGetter,
      },
    }),
    [cellEditorParams, valueGetter]
  );
};

export const useUnifiedLiquidityColumn = (): Column | null => {
  const { showSecurityMasterUnifiedLiquidityColumn } = useFeatureFlag();
  const { unifiedLiquidityTokenBySymbol } = useUnifiedLiquidityContext();

  const isDisabled = useMemo(() => {
    return (data: CustomerSecurity) => {
      const hasLiquidityTokenSymbol = unifiedLiquidityTokenBySymbol?.get(data.Symbol);
      return !hasLiquidityTokenSymbol;
    };
  }, [unifiedLiquidityTokenBySymbol]);

  const unifiedLiquidityColumn: Column = useMemo(
    () => ({
      type: 'toggle',
      field: 'UnifiedLiquidity',
      title: 'Unified Liquidity',
      description: 'Leverage Unified Liquidity for pricing and execution',
      width: 130,
      suppressMenu: false,
      params: { isDisabled } satisfies ToggleParams,
    }),
    [isDisabled]
  );

  return useMemo(
    () => (showSecurityMasterUnifiedLiquidityColumn ? unifiedLiquidityColumn : null),
    [showSecurityMasterUnifiedLiquidityColumn, unifiedLiquidityColumn]
  );
};
