import type { ReactNode } from 'react';
import { IconButton } from '../Button';
import type { ToggleProps } from '../Form';
import { FormControlSizes } from '../Form/types';
import { IconName } from '../Icons';
import { Popover, usePopoverState, type PopoverState } from '../Popover';
import { BlotterMenuWrapper } from './styles';

interface BlotterTableExtrasMenuProps extends PopoverState {
  children: ReactNode;
}

/** Common tooltip placement for Extras Menu controls
 * so that the tooltip doesn't hover over other items
 * (for now in toggle, but expand as needed to other contorls) */
export const EXTRAS_MENU_TOOLTIP_PROPS: ToggleProps['tooltipProps'] = {
  placement: 'left',
};
/**
 * The BlotterTableExtrasMenu gives you the kebab menu and a popover within which you can render your Toggles, buttons, and similar.
 */
export function BlotterTableExtrasMenu({ children, ...popover }: BlotterTableExtrasMenuProps) {
  return (
    <Popover {...popover}>
      <IconButton
        ghost
        icon={IconName.DotsVertical}
        size={FormControlSizes.Small}
        data-testid="blotter-table-extras-menu"
      />
      <BlotterMenuWrapper>{children}</BlotterMenuWrapper>
    </Popover>
  );
}

export function useBlotterTableExtrasMenu() {
  return usePopoverState({
    closeOnClickOutside: true,
    trigger: 'click',
    placement: 'bottom-end',
    usePortal: true,
  });
}
