import { EMPTY_OBJECT } from '@talos/kyoko';
import { useState } from 'react';
import { useReconOverviewDataObs } from './blotter/useReconOverviewDataObs';
import { useReconCheckpointNavigation } from './useReconCheckpointNavigation';

/** Combines useReconCheckpointNavigation with the Checkpoint blotter's data obs hook */
export const useReconOverviewDataObsWithNav = ({ checkpointIDs }: { checkpointIDs?: string[] } = EMPTY_OBJECT) => {
  const [selectedStartTime, setSelectedStartTime] = useState<Date>();
  const [selectedEndTime, setSelectedEndTime] = useState<Date>();

  const { dataObservable, dataIsLoading, latestCheckpointsMetadata } = useReconOverviewDataObs({
    checkpointIDs,
    startTime: selectedStartTime,
    endTime: selectedEndTime,
  });

  const reconCheckpointNav = useReconCheckpointNavigation({
    latestReceivedStartTime: latestCheckpointsMetadata?.StartTime,
    latestReceivedEndTime: latestCheckpointsMetadata?.EndTime,
    selectedStartTime,
    selectedEndTime,
    onChangeStartTime: setSelectedStartTime,
    onChangeEndTime: setSelectedEndTime,
  });

  return {
    /** The blotter data observable of checkpoints */
    dataObservable,
    /** Loading state of dataObservable */
    dataIsLoading,
    /** The return of useReconCheckpointNavigation -- containing the API to navigate and give to the Nav component */
    reconCheckpointNav,
    /** The StartTime of the last received set of checkpoints */
    dataStartTime: latestCheckpointsMetadata.StartTime,
    /** The EndTime of the last received set of checkpoints */
    dataEndTime: latestCheckpointsMetadata.EndTime,
    /** The LastUpdateTime of the last received set of checkpoints */
    dataLastUpdateTime: latestCheckpointsMetadata.LastUpdateTime,
  };
};
