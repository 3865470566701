import {
  FormControlSizes,
  IconName,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  type Column,
  type ColumnDef,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, ICellRendererParams, MenuItemDef } from 'ag-grid-community';
import { compact } from 'lodash';
import { useMemo, type ReactNode } from 'react';
import { ReconAssetRow, type SubAccountReconOverviewRow } from './reconCheckpointRows';

function showDetailsButton(rowData: SubAccountReconOverviewRow | undefined) {
  return rowData instanceof ReconAssetRow;
}

function showResolveButton(rowData: SubAccountReconOverviewRow | undefined) {
  return rowData instanceof ReconAssetRow;
}

function enableResolveButton(rowData: SubAccountReconOverviewRow | undefined) {
  return showResolveButton(rowData) && rowData.hasBreak;
}

export function useSubAccountReconOverviewBlotterMenu({
  onDetailsClicked,
  onResolveClicked,
}: {
  onDetailsClicked?: (assetRow: ReconAssetRow) => void;
  onResolveClicked?: (assetRow: ReconAssetRow) => void;
}): {
  columns: Column[];
  getContextMenuItems: (params: GetContextMenuItemsParams) => (MenuItemDef | string)[];
  dialogs: ReactNode;
} {
  const { handleClickJson, jsonModal } = useJsonModal();

  const defaultContextMenuItems = useGetDefaultContextMenuItems();

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams) => {
    const data = params?.node?.data;

    const resolveBreakItem =
      showResolveButton(data) && onResolveClicked
        ? {
            name: 'Resolve',
            action: () => onResolveClicked?.(data),
            icon: `<i class="ag-icon ${IconName.CheckCircle}"/>`,
          }
        : undefined;

    const detailsItem =
      showDetailsButton(data) && onDetailsClicked
        ? {
            name: 'Open Details',
            action: () => onDetailsClicked?.(data),
            icon: `<i class="ag-icon ${IconName.Deepdive}"/>`,
          }
        : undefined;

    const showJsonItem = data
      ? {
          name: `Show JSON`,
          action: () => handleClickJson(data),
          icon: `<i class="ag-icon ${IconName.Braces}"/>`,
        }
      : undefined;

    const items = compact([
      detailsItem,
      resolveBreakItem,
      'separator',
      showJsonItem,
      'separator',
      ...defaultContextMenuItems(params),
    ]);
    return items;
  });

  const dialogs = useMemo(() => <>{jsonModal}</>, [jsonModal]);

  const columns = useMemo(
    () =>
      compact([
        onResolveClicked && {
          type: 'button',
          id: 'resolve-checkpoint',
          suppressColumnsToolPanel: true,
          pinned: 'right',
          frozen: true,
          width: 70,
          params: {
            hide: (data: SubAccountReconOverviewRow | undefined) => {
              return !showResolveButton(data);
            },
            onClick: (params: ICellRendererParams<SubAccountReconOverviewRow>) => {
              if (params.node.data instanceof ReconAssetRow) {
                onResolveClicked(params.node.data);
              }
            },
            disabled: params => {
              const data: SubAccountReconOverviewRow | undefined = params.node.data;
              return !enableResolveButton(data);
            },
            size: FormControlSizes.Small,
            children: 'Resolve',
          },
        },
        onDetailsClicked && {
          type: 'iconButton',
          id: 'open-details',
          frozen: true,
          pinned: 'right',
          suppressColumnsToolPanel: true,
          width: 45,
          params: {
            icon: IconName.Deepdive,
            hide: params => {
              const data: SubAccountReconOverviewRow | undefined = params.node.data;
              return !showDetailsButton(data);
            },
            onClick: params => {
              const data: ReconAssetRow | undefined = params.node.data;
              if (data) {
                onDetailsClicked(data);
              }
            },
          },
        },
      ]) satisfies ColumnDef<SubAccountReconOverviewRow>[],
    [onDetailsClicked, onResolveClicked]
  );

  return {
    columns,
    getContextMenuItems,
    dialogs,
  };
}
